* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
a.header-menu-1.donation-div-menu img {
  width: 110px;
}
@media (max-width: 767px) {
  .contact-logo img {
    width: 95px;
    padding: 22px 0px;
  }
  h2.cart-text {
    font-size: 23px;
  }
  .contact-logo {
    display: block !important;
    text-align: center;
  }

  .iframe-img img {
    display: none;
  }
  .video-soundchat {
    height: 100% !important;
  }
  .contact-middle-div {
    margin-top: 0px !important;
  }
  .right-section-listen > h6 {
    font-size: 18px !important;
  }
}
.iframe-img img {
  height: 650px;
  width: 100%;
  object-fit: cover;
  background-position: center;
}
.copyright-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
body {
  width: 100%;
  font-family: "Regular";
  touch-action: pan-x pan-y;
  background-color: #000 !important;
  /* font-family: "Poppins", sans-serif !important; */
  height: 100vh;
}
ul li {
  list-style-type: none !important;
}
a {
  text-decoration: none !important;
}
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  display: none;
}
.owl-theme .owl-nav {
  margin-top: 0px !important;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
/****************** Header top ********************/
.navbar {
  margin-bottom: 0px;
}
.section-div-right {
  display: flex;
  align-items: center;
}
.heading-section-h1 h1 {
  margin: 10px 0px;
  padding: 20px 0px;
  color: white;
  font-size: 30px;
  font-weight: 700;
}
.header-top-wrapper {
  width: 100%;
  height: 90px;
}
.header-section-one {
  width: 68%;
}
.header-section-one img {
  width: 100%;
  height: 90px;
}
.header-section-tow {
  width: 32%;
  background-color: #3d3939;
}

/* .logo-soundchat img {
  width: 85px;
  height: 90px;
} */

.logo-soundchat img {
  width: 100%;
  height: 99px;
}
.logo-text-heading p {
  margin-bottom: -5px;
  font-size: 13px;
  color: #fff;
}
.recap-video-heading.recap-d1 {
  position: absolute;
  box-shadow: inset 587px 4px 82px -66px #000000c2 !important;
}
.bottom-pera {
  color: #000;
  font-size: 14px;
  font-weight: 500;
}
.div-h6 {
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 500;
  margin-top: 8px;
}

.bg-color {
  background-color: #000;
}
/****************** HEADER BO***************/
.header-menu-2 {
  display: none;
}
.header-bottam {
  position: sticky;
  width: 100%;
  background: #ffc107;
  top: 99px;
  z-index: 1024;
}
/* .section-div-right-1 {
  display: none;
} */
.header-menu-1 {
  font-size: 21px !important;
  color: #000 !important;
  margin-right: 13px !important;
}
small.bottom-pera.header-menu-1 {
  font-size: 14px !important;
}
.header-menu-1-div {
  font-size: 23px !important;
  color: #000 !important;
  margin-right: 13px !important;
}

h1.header-coutong {
  font-size: 12px;
  color: #fff;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #000;
  position: absolute;
  top: -12px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 20px;
}
a.header-menu {
  padding: 0px 9px;
  color: #000000;
  text-decoration: none !important;
  font-size: 15px;
}

.header-menu-0 li {
  color: #000;
}

.header-backwardicon {
  margin-top: 8px !important;
}
.header-menu:hover {
  color: #fff !important;
  border-bottom: 1px solid;
}

/* .navbar-collapse {
  padding-right: 3px;
  padding-left: 9px;
} */
.header-humger {
  width: 30px;
  height: 30px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: #000;
  border-radius: 50%;
  cursor: pointer;
}

.social-icon-div {
  display: flex;
}
.social-icon-div li {
  color: white;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  margin: 3px;
}
.pointer {
  cursor: pointer;
}

/***************************** layout section ********************/
.Soundchat-div {
  padding: 15px 10px;
}
.video-soundchat {
  border: 5px solid #ffc107a3;
  position: relative;
  border-radius: 20px;
  height: 530px;
}
.video-live-home {
  height: 100%;
}
.video-live-home .react-player {
  height: 100% !important;
}
.video-live-home .react-player video {
  height: 100% !important;
  object-fit: cover;
  border-radius: 15px;
}
/************************ PODCAST SECTION START *****************/
.podcast-vo-div {
  width: 100% !important;
  margin: 15px 0px 0px 0px;
  border: 2px solid #ffc107a8;
  border-radius: 17px;
  line-height: 8px;
}
.podcast-img-div {
  padding-top: 14px;
}
.podcast-bo-box {
  padding: 6px;
  text-align: left;
  border-radius: 0px 0px 14px 14px;
}
.podcast-bo-box > h6 {
  font-size: 16px;
  color: #ffc107;
  font-weight: 500;
}
.podcast-bo-box > p {
  color: white;
  font-size: 12px;
  font-weight: 300;
}

.podcast-bottam > .nav-tabs {
  background-color: #ffffff;
}
.podcast-bottam {
  padding-top: 30px;
}

.podcast-bottam > .nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  background: 0 0;
  border: none !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}
.podcast-bottam .nav-tabs .nav-link.active {
  color: #dc3545 !important;
}

.podcast-bottam > .nav-tabs .nav-item {
  padding: 13px;
  font-size: 16px;
  font-weight: 500;
  margin: auto;
  text-align: center;
  color: #000 !important;
  background-color: transparent;
}
.table-responsive {
  border-collapse: collapse;
  overflow-x: auto;
  display: block;
  max-width: 100%;
}

.podcast-img img {
  width: 127px !important;
  border-radius: 50%;
  height: 127px;
  margin: 0 auto;
  border: 4px solid #fff;
  position: relative;
}
.inter-n-veiw {
  padding: 5px 15px;
}

.podcast-box-pod h6 {
  color: white;
  text-align: center;
  font-size: 13px;
  padding-top: 10px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.podcast-img-div img {
  width: 120px !important;
  border-radius: 50% !important;
  height: 120px !important;
  margin: 0 auto !important;
  border: 4px solid #ffc107a8 !important;
}

/************************ PODCAST SECTION END *****************/

/************************ NEWS SECTION START *****************/

.swiper {
  width: 100%;
  /* height: 100%; */
}
.head > h2 {
  font-weight: 400;
  color: white;
  padding: 0px 0px 10px 0px;
  font-size: 20px;
  margin-bottom: 0px;
}

.mySwiper > .swiper-slide {
  font-size: 18px !important;
  /* background: #fff; */
}
.product-section {
  width: 100%;
  position: relative;
  padding: 5px 10px;
  height: 100%;
}
.live-podcast,
.mySwiper {
  margin: 5px 0px;
}

.content {
  position: absolute !important;
  bottom: 0 !important;
  color: #f1f1f1 !important;
  width: 100% !important;
  height: 182px !important;
  box-shadow: inset 0 -70px 9px 8px #000000c2 !important;
  padding-top: 120px !important;
}

.swiper-backface-hidden .swiper-slide {
  transform: translateZ(0);
  backface-visibility: hidden;
  border-radius: 25px;
  background: transparent;
}
horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: var(--swiper-pagination-bottom, 8px);
  top: var(--swiper-pagination-top, auto);
  left: 0;
  width: 100%;
  visibility: hidden;
}
.cover-text.slide.animate-text {
  visibility: hidden;
}
.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 4px);
  visibility: hidden;
  display: none;
}
.news-video-section .react-player__preview {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 25px 25px 0px 0px;
  justify-content: center;
}
.icon-news-div {
  color: #ffc107;
  text-align: center;
}

.news-video-icn {
  position: relative;
  display: block;
  margin: 0 auto;
  text-align: center;
  align-items: baseline;
  font-size: 26px;
  /* bottom: 6px; */
  border: 4px solid #fff;
  width: 50px;
  cursor: pointer;
  top: 87px;
  height: 50px;
  border-radius: 50%;
}
.day-div p {
  font-size: 11px !important;
  padding: 1px 14px;
  margin-bottom: 2px;
  color: #fff !important;
}
.div-news-h6 {
  color: #ffc107;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0px;
  text-align: center;
}
.content > p {
  font-size: 13px;
  margin-bottom: 0px;
  line-height: 18px;
  font-weight: 400;
  text-align: center;
}
.news-video-section {
  width: 100%;
}

.ReactModal__Content {
  position: absolute;
  inset: 0px;
  border: none;
  background: transparent;
  overflow: hidden;
  border-radius: 0px;
  outline: none;
  padding: 0px;
  margin-top: 36px !important;
}
/************************ NEWS SECTION END *****************/
/************************ INTERVIEW SECTION STATR *****************/
.current-trn-section h5 {
  text-align: center;
  background: #1c1b1bc4;
  color: white;
  font-size: 22px;
  padding: 10px 0px;
  font-weight: 400;
}
.news-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.video-slider img {
  opacity: 87% !important;
  width: 100%;
  height: 600px;
  object-fit: cover;
  background-position: center;
}
.recap-video-heading {
  position: absolute;
  box-shadow: inset 587px 4px 82px -66px #000000c2 !important;
  top: 0px;
  left: 0px;
  padding: 243px 36px 0px 19px;
  bottom: 0px;
  width: 593px;
  color: white;
  /* opacity: 52%; */
}
/************************ NEWS INTERVIEW SECTION STATR *****************/

/************************ FOOTER SECTION START *****************/

.live-podcast img {
  border-radius: 10px;
  border: 2px solid #ffc107a3;
  margin-bottom: 10px;
}

.listen-img-div {
  box-shadow: inset rgb(238 232 232 / 32%) 3px 0px 28px;
  /* height: 655px; */
  width: 100%;
  background: #212529;
  /* padding: 9px; */
  border-radius: 20px;
}

ol,
ul {
  padding-left: 0px !important;
}

.title {
  font-size: 18px;
  color: white;
}
.links-list li {
  margin: 15px 0px;
  font-size: 16px !important;
  color: white;
  text-align: left !important;
}
.button-div {
  width: 140px;
  cursor: pointer;
  margin: auto;
}
button.btn.btn-div-plya.d-flex {
  width: 175px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-div-plya {
  border: 1px solid white !important;
  font-size: 15px !important;
  color: white !important;
}
button.btn.btn-div-plya.d-flex {
  width: 175px;
  height: 48px;
}
.btn-div-plya .google-div {
  width: 21px;
  display: block;
}
.google-div img {
  width: 100%;
}
.div-iphone {
  margin-top: 20px;
}

ul.links-list.margin-div {
  margin-left: 29px;
  margin-right: 31px;
  /* padding-left: 72px; */
}

.sc-div {
  width: 170px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin-top: 15px;
}
.sc-div img {
  height: 100%;
  width: 100%;
}

.button-div img {
  width: 100%;
}
/*******************recap tv shows section********************/
.interview-div {
  padding: 0px 20px 10px 20px;
}
.currently-main-div {
  padding: 0px 20px;
}
.view-more-sect-div {
  padding: 0px 10px;
}
.recap-div {
  padding: 20px 0px;
}
.slider-new {
  position: relative;
  width: 100%;
  height: 100%;
}
.video-player .react-player__play-icon {
  border-style: solid;
  border-width: 16px 0px 16px 26px;
  border-color: transparent transparent transparent white;
  margin-left: 7px;
  display: none !important;
}

.recap-video-heading .recap-video-h1 {
  font-size: 40px;
  font-weight: 800;
  margin-top: 10px;
}
.recap-video-heading .recap-video-pra {
  font-size: 16px;
  font-weight: 400;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.recap-icon-w-div {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 36px;
  font-size: 14px;
  padding-left: 2px;
  height: 20px;
  border-radius: 50%;
  background-color: red;
  width: 20px;
  margin-top: 13px;
  color: #fff;
}
.video-recap-btn-div button {
  border-radius: 21px;
  letter-spacing: 1px;
  padding: 6px 32px;
  background-color: transparent;
  color: white;
  font-size: 18px;
  font-weight: 700;
  border: 2px solid white;
  margin-top: 15px;
}
.video-recap-btn-div button:hover {
  background-color: #fff;
  color: #000;
  box-shadow: 0px 2px 7px 1px #fff;
}

.video-recap-btn-div {
  display: flex;
  align-items: center;
}

.recap-video-heading .recap-div-p {
  font-size: 18px;
  background-color: #3d3939;
  padding: 5px 10px;
  font-weight: 400;
  border: none;
}
.img-recap-home img {
  object-fit: cover;
  width: 100% !important;
  height: 162px;
  border-radius: 15px 15px 0px 0px;
  cursor: pointer;
  border: 2px solid #ffc107a3;
}
.img-recap img {
  object-fit: cover;
  width: 100% !important;
  height: 210px;
  border-radius: 15px 15px 0px 0px;
  cursor: pointer;
  border: 2px solid #ffc107a3;
}
.show-section {
  padding: 20px;
}
.news-heading h3 {
  font-size: 20px;
  color: white;
  margin-bottom: 0px;
  font-weight: 600;
  padding: 10px 0px;
  margin-top: 0px;
}
.heading-div-new {
  display: flex;
}
p.listen-second {
  text-align: left;
  margin-bottom: 0px;
  bottom: 0px;
  font-size: 13px;
  font-weight: 600;
  color: #ffc107;
}
.slide-fed-new {
  position: relative;
}
.fade-in-new img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 13px;
}
.home-slider-adv img {
  width: 100%;
  height: 463px;
  /* object-fit: fill; */
}
p.listen-first {
  font-size: 12px;
  text-align: left;
  font-weight: 600;
  color: #ffc107;
  margin-bottom: -5px;
}
.reacp-listen {
  position: absolute;
  bottom: 0;
  color: #f1f1f1;
  padding: 0px 7px;
  width: 100%;
  height: 67px;
  background-color: #0000009c;
  border-radius: 0px;
}
h6.recap-listenn-pra {
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  max-width: 160px;
  text-overflow: ellipsis;
  margin-bottom: 0px;
}
.reacp-contnent {
  position: absolute;
  bottom: 0;
  color: #f1f1f1;
  width: 100%;
  height: 30px;
  background-color: #0000009c;
  border-radius: 0px;
}

.reacp-contnent-home p.recap-pra {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.reacp-contnent-home {
  position: absolute;
  bottom: 0;
  color: #f1f1f1;
  width: 100%;
  line-height: 18px;
  height: 27px;
  text-align: center;
  font-size: 15px;
  background-color: #0000009c;
  border-radius: 0px;
  padding: 5px;
  margin-bottom: 0px;
}

.view-recap-more {
  position: relative;
  margin-bottom: 20px;
}
.view-recap-more img {
  position: relative;
  width: 100%;
  height: 260px;
  object-fit: cover;
  border-radius: 15px 15px 0px 0px;
}
.reacp-contnent .recap-pra {
  font-size: 13px;
  font-weight: 500;
  overflow: hidden;
  text-align: center;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  margin-bottom: 0px;
  padding: 2px 4px;
}
.heading-h5-div h5 {
  text-align: center;
  font-size: 14px;
  color: white;
  margin-bottom: 18px;
}

.news-heading h6 {
  color: #ffc107;
  font-size: 13px;
  margin-bottom: 0px;
}

.news-heading.bg-div-color h5 {
  overflow: hidden;
  white-space: nowrap;
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content;
  text-overflow: ellipsis;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0px;
  margin-top: 0px;
}

.news-heading.bg-div-color {
  color: #fff;
  background-image: linear-gradient(73deg, #ffc107ba, #0c710514);
  padding: 12px 10px;
  margin: 20px 0px;
}

.recap-p-div {
  margin-top: -24px;
}

/*************************** view more pages***********************/
.recap-view-more {
  text-align: center;
  position: absolute;
  height: 43px;
  bottom: 0;
  color: #f1f1f1;
  padding: 9px 0px;
  width: 100%;
  box-shadow: inset 0 -112px 13px -8px #000000a8;
  line-height: 17px;
}
.recap-view-more .recap-pra-view-more {
  font-size: 14px;
  color: white;
}

.recap-view-more .small-pra-view {
  font-size: 12px;
  color: white;
  padding-left: 12px !important;
}

/********** mawnin vibes video section ***************/

.logo-video-heading h4 {
  margin-left: 50px;
  background-color: #670716;
  width: 400px;
  padding: 10px 0px;
  color: white;
}
.logo-video-section {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.logo-video {
  width: 52px;
  background-image: linear-gradient(95deg, #3f51b5, #670716);
  border-radius: 50%;
}

.logo-video img {
  width: 100%;
  display: flex;
  height: 50px;
  padding: 4px 10px;
  align-items: center;
  justify-content: center;
}

.video-recap-img img {
  width: 100%;
  height: 200px;
}

.pagination > .active > a {
  background-color: #ffc107;
  border-color: #ffc107;
}
.pagination > li > a:focus {
  display: none !important;
}
.pagination > li > a,
.pagination > li > span {
  position: relative;
  color: #ffc107;
  font-size: 14px;
  margin: 0px 3px;
}
.recap-seaven-div-one-one-div-rec {
  position: absolute;
  right: 64px;
  bottom: 0px;
  width: 140px;
  height: 258px;
  box-shadow: inset 0 -268px 9px -8px #000000a8;
  text-align: right;
  color: white;
  font-size: 12px;
  padding: 116px 0px;
  font-weight: 500;
}
.video-recap-img img {
  border-radius: 10px;
}
.shop-section {
  padding: 20px;
}
.shop-img {
  width: 250px;
}
.shop-img img {
  width: 100%;
  height: 280px;
  border-radius: 15px;
}
.shop-p p {
  font-size: 17px;
  text-align: center;
  color: white;
}
.shop-i img {
  padding-top: 14px;
  width: 100%;
  /* height: 500px; */
}
label.form-control-1-lable {
  font-size: 14px;
  margin-bottom: 7px;
}
.shop-right-head {
  font-size: 25px;
  color: rgba(89, 84, 84, 0.75);
  font-weight: 500;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}
.shop-right-pra {
  color: red;
  font-size: 15px;
}
.shop-r-pra {
  color: rgba(89, 84, 84, 0.75);
  font-size: 17px;
}

.podcast {
  padding: 20px 15px;
}

.podcast-box h6 {
  color: #010101;
  text-align: center;
  font-size: 15px;
  padding-top: 10px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.div-podcast {
  text-align: center !important;
  color: #ffc107;
  font-size: 12px;
  font-weight: 400;
}

.pod-img img {
  width: 100%;
  border-radius: 14px 14px 0px 0px;
  height: 100%;
}

.mediy-gallery-div {
  padding: 20px 20px 0px 20px;
  background-color: #fff;
}
.watch-section {
  padding-top: 8px;
}

.media-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}
.media-gallary-heading h3 {
  font-size: 22px;
  margin: 0px 0px;
  color: #1e1e1e;
  padding: 11px 19px 11px 20px;
  font-weight: 700;
  background-color: #fff;
}

.gallery {
  padding: 7px 7px;
  background-color: #fff;
  width: 100%;
}

.img-gallery img {
  width: 100%;
  height: 540px;
  border-radius: 10px;
  object-fit: cover !important;
}
.img-gallery-div img {
  width: 100%;
  height: 260px;
  border-radius: 10px;
}
.img-gallery-div {
  padding: 0px 9px 7px 3px;
}
.right-section-listen {
  padding: 20px;
}
.listen-div {
  margin-top: 0px;
  margin-bottom: 8px;
  min-height: 660px;
  border: 2px solid #ffa50040;
  border-radius: 22px;
}

.video-lis-btn-div .btn-lis {
  border-radius: 21px;
  padding: 7px 29px;
  background-color: transparent;
  color: white;
  font-size: 11px;
  border: 1px solid white;
  margin-top: 15px;
}
.listen-img-div img {
  width: 100%;
  object-fit: cover;
  height: 480px;
  border-radius: 20px 20px 0px 0px;
}

.listen-icon-div {
  /* margin: auto; */
  display: flex;
  /* text-align: center; */
  color: white;
  /* width: 100%; */
  justify-content: center;
}

.listen-icon-div .listion-icon {
  font-size: 11px;
  margin: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 2px solid white;
}

section.view-story1 img {
  width: 100%;
  height: 500px !important;
  border-radius: 0px !important;
}
.right-section-listen > h6 {
  font-size: 20px;
  width: 100%;
  border-bottom: 1px solid;
  color: white;
  margin: auto;
  padding-bottom: 9px;
}
.left-player-body p {
  font-size: 15px;
  padding-top: 5px;
  margin-bottom: -10px;
}
.right-section-listen > p {
  color: white;
  font-size: 12px;
  padding-top: 8px;
  margin-bottom: 3px;
}
.listen-new-item {
  padding-top: 10px;
}
/* 
contact us */

#contact {
  width: 100%;
  height: 100%;
}
p.contact-prgrap {
  font-size: 15px;
  margin-bottom: 23px;
  color: #fff;
  margin-top: -2px;
}
.section-header {
  padding: 0px 0px 0px;
  font-size: 24px;
  color: #fff;
}
.send-button {
  margin-top: 14px;
  height: 44px;
  border-radius: 30px;
  color: #fff;
  border: 1px solid #fff;
  width: 100%;
  background: transparent;
}
.send-text {
  font-size: 14px;
  letter-spacing: 2px;
}
.list-item i {
  font-size: 20px;
  line-height: 40px;
}
.list-item {
  color: #fff;
}

.contact-text h5 {
  font-size: 15px !important;
  padding-left: 37px;
  color: #fff !important;
}
ul.contact-list h6 {
  font-size: 20px;
  color: #cf9d0c;
  width: 413px;
  padding-bottom: 11px;
  border-bottom: 1px solid #fff !important;
}
h1.contact-header-form {
  font-size: 20px;
  color: #cf9d0c;
  padding-bottom: 11px;
}
.contact-text {
  font-size: 15px;
  color: #fff;
}

.place {
  margin-left: 15px;
}

.phone {
  margin-left: 15px;
}

.gmail {
  margin-left: 15px;
}

.contact-text a {
  color: #fff;
  text-decoration: none;
  transition-duration: 0.2s;
}

.contact-text a:hover {
  color: #fff;
  text-decoration: none;
}
.social-media-list {
  position: relative;
}

.form-control::placeholder {
  color: #fff !important;
}

.form-control {
  color: #fff !important;
  display: block !important;
  width: 100% !important;
  padding: 23px !important;
  margin-bottom: 0px !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  background-color: transparent !important;
  border: 1px solid #fff !important;
  border-radius: 30px !important;
}

.social-media-list li a {
  color: #fff;
  top: -9px;
  left: 8px;
  position: absolute;
  font-size: 13px;
}
.form-horizontal .form-group {
  margin-left: 0px;
  margin-right: 0px;
}
.social-media-list li {
  position: relative;
  display: inline-block;
  height: 30px;
  width: 30px;
  margin: 10px 8px;
  line-height: 43px;
  border-radius: 50%;
  color: #fff;
  background-color: transparent;
  cursor: pointer;
  border: 1px solid #fff;
}
.social-media-list li:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 30px;
  line-height: 48px;
  border-radius: 50%;
  opacity: 0;
  box-shadow: 0 0 0 1px #5f6a74;
}
.social-media-list li:hover {
  background-color: #fff;
  height: 30px;
  width: 30px;
  border: none;
}
.social-media-list li:hover a {
  color: #000;
}

/******** Shop New  ***************************/
.single-product img {
  width: 100%;
}
.section-products .header {
  text-align: center;
  margin-top: 28px;
  margin-bottom: 28px;
}

.section-products .header h3 {
  font-size: 16px;
  color: #c59810;
  font-weight: 400;
}
.section-products .header h2 {
  font-size: 28px;
  font-weight: 500;
  color: #000;
}

.section-products .single-product {
  margin-bottom: 26px;
  background-color: #fff;
  border-radius: 5px;
  position: relative;
}
.section-products .single-product img {
  border-radius: 5px;
  height: 310px;
  object-fit: cover;
}
span.discount {
  background: #ffc107;
  padding: 6px 11px;
  border-radius: 5px;
  color: #fff;
  font-size: 11px;
  position: absolute;
  top: 13px;
  left: 8px;
  font-weight: 600;
}
.section-products .single-product ul li {
  /* background: #000; */
  color: #5f5858;
  position: relative;
  top: 0;
  /* right: 10px; */
  font-size: 14px;
  font-weight: 600;
}
.product-detail-data h3 {
  height: 30px;
  /* display: flex;
  align-items: center; */
  color: #000;
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content;
  text-overflow: ellipsis;
}
.product-detail-data {
  padding: 15px;
  position: relative;
}
h4.product-price {
  color: #f8a50d;
  font-size: 20px;
}
a.view-me {
  position: absolute;
  right: 22px;
  bottom: 22px;
  color: #6a6666;
  width: 32px;
  height: 32px;
  background: #fff;
  box-shadow: 1px 1px 5px 1px #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 17px;
}

.section-products .single-product .part-1 {
  position: relative;
  overflow: hidden;
}
.part-2 {
  padding: 13px 12px;
}
.section-products .single-product .part-1::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: all 0.3s;
}

.section-products .single-product:hover .part-1::before {
  transform: scale(1.2, 1.2) rotate(5deg);
}
.section-products .single-product .part-1 .discount,
.section-products .single-product .part-1 .new {
  position: absolute;
  top: 11px;
  left: 0px;
  color: #ffffff;
  background-color: #fe302f;
  padding: 2px 8px;
  text-transform: uppercase;
  font-size: 11px;
}

.section-products .single-product .part-1 .new {
  left: 0;
  background-color: #444444;
}

.section-products .single-product .part-1 ul {
  position: absolute;
  bottom: -41px;
  left: 15px;
  opacity: 0;
  transition: bottom 0.5s, opacity 0.5s;
}

.section-products .single-product:hover .part-1 ul {
  bottom: 30px;
  opacity: 1;
}

.section-products .single-product .part-1 ul li {
  font-size: 19px;
}

.section-products .single-product .part-1 ul li a {
  margin-bottom: 8px;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 36px;
  background-color: orange;
  color: #000;
  font-weight: 800;
  text-align: center;
}

.section-products .single-product .part-2 .product-title {
  font-size: 17px;
  font-weight: 700;
  color: #000;
  overflow: hidden;
  white-space: nowrap;
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content;
  text-overflow: ellipsis;
}

.section-products .single-product .part-2 h4 {
  display: inline-block;
  font-size: 17px;
  font-weight: 700;
  color: #ffc107;
}

.section-products .single-product .part-2 .product-old-price {
  position: relative;
  padding: 0 1px;
  margin-right: 9px;
  opacity: 0.7;
  font-size: 15px;
  font-weight: 400;
  color: #dc3545;
}

.section-products .single-product .part-2 .product-old-price::after {
  position: absolute;
  content: "";
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #d90015;
  transform: translateY(-50%);
}
.listen-showw {
  padding: 10px 10px;
}
.podcast-bottam .nav-tabs .nav-link:focus {
  display: none !important;
}
section.new-store {
  padding: 20px 0px 20px 0px;
  position: relative;
  background-image: url(https://soundchatradio.com/wp-content/uploads/2021/04/sc-footer-bgn2.jpg);
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100% !important;
}
.shop-text h1 {
  text-align: left;
  font-size: 56px;
  font-weight: 600;
  color: #fff;
  padding: 100px 0px 0px 0px;
}
svg.shop-11 {
  margin-top: -3px;
  margin-right: 10px;
}
.store-img img {
  width: 100%;
  height: 500px;
  object-fit: cover;
}
.shop-text button {
  text-align: center;
  float: left;
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
  margin: 17px 0px 0px 0px;
  font-size: 16px;
  padding: 10px 44px;
}
.shop-text h4 {
  text-align: left;
  color: #ffc107;
  padding-top: 4px;
  font-size: 28px;
}
.pd-wrap {
  padding: 40px 0;
}
.heading-section {
  text-align: center;
  margin-bottom: 20px;
}
.sub-heading {
  font-size: 12px;
  display: block;
  font-weight: 600;
  color: #2e9ca1;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.heading-section h2 {
  font-size: 32px;
  font-weight: 500;
  padding-top: 10px;
  padding-bottom: 15px;
}

.product-thumb .item img {
  height: 100px;
}
.product-name {
  font-size: 30px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 4px;
}
.product-price-discount {
  font-size: 22px;
  font-weight: 400;
  padding: 10px 0;
  clear: both;
}
.product-price-discount span.line-through {
  text-decoration: line-through;
  margin-left: 10px;
  font-size: 17px;
  vertical-align: middle;
  color: #000;
}
.product-price-discount span {
  font-size: 20px;
  font-weight: 600;
  color: #6e5508;
}
.display-flex {
  display: flex;
}
.align-center {
  align-items: center;
}
.product-info {
  width: 100%;
}
.reviews-counter {
  font-size: 13px;
}
.reviews-counter span {
  vertical-align: -2px;
  font-size: 17px;
}
.product-dtl p {
  font-size: 15px;
  padding-bottom: 3px;
  line-height: 28px;
  color: #000;
}
.product-count {
  font-size: 16px;
  margin-top: 10px;
}
.product-dtl {
  padding-top: 41px;
  padding-left: 45px;
}
.product-count .qtyminus,
.product-count .qtyplus {
  width: 44px;
  height: 44px;
  display: flex;
  /* text-align: center; */
  justify-content: center;
  align-items: center;
  font-size: 24px;
  /* line-height: 31px; */
  color: #000;
  border: 1px solid;
  cursor: pointer;
}
.qnt {
  display: flex;
  align-items: center;
  font-size: 20px;
  justify-content: center;
  /* padding: 7px 0px 0px 0px; */
  width: 70px;
  text-align: center;
  border: 1px solid;
  font-weight: 600;
}
.round-black-btn {
  border-radius: 1px;
  background: orange;
  min-width: 350px;
  color: #000;
  padding: 11px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  font-size: 20px;
  font-weight: 600;
  border: none;
  /* border: solid 2px #212529; */
  /* transition: all 0.5s ease-in-out 0s; */
}

.review-form .form-group {
  clear: both;
}
.mb-20 {
  margin-bottom: 20px;
}
.rating ul {
  display: flex;
}
.rating ul li {
  margin-right: 7px;
}

.review-heading {
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 6px;
  text-transform: uppercase;
  color: #000;
}
.review-form .form-control {
  font-size: 14px;
}
.review-form input.form-control {
  height: 40px;
}
.review-form textarea.form-control {
  resize: none;
}
.review-form .round-black-btn {
  text-transform: uppercase;
  cursor: pointer;
}
.product-variation {
  display: flex;
  align-items: center;
  padding: 15px 0px;
  justify-content: space-between;
}
.product-label-text {
  font-size: 15px;
  white-space: nowrap;
}
.range-variant {
  display: flex;
  margin: 0 0px 0;
}
.range-variant li {
  border: 2px solid #e1e2e9;
  background: #fff;
  border-radius: 51px;
  min-width: 30px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  margin: 3px;
  cursor: pointer;
  font-size: 10px;
}

.store-description p {
  font-size: 14px;
  line-height: 24px;
  color: #000;
}
.store-description h4 {
  font-size: 23px;
}
.store-tabs {
  margin-top: 37px !important;
}

section.bg {
  background: #000;
  position: relative;
}
.store-tabs .nav-tabs .nav-link.active {
  color: #ffc107;
  font-size: 18px;
  background-color: #000;
  padding: 0.55rem 3rem;
  border-bottom: 2px solid #ffc107;
}
.nav-tabs {
  border: 1px solid #c7bfbf !important;
}
.store-shoppiner {
  background: #e3e3de;
}
.store-tabs .nav-tabs .nav-link {
  color: #000;
  font-size: 18px;
  padding: 0.55rem 3rem;
}
.form-control-1 {
  display: block !important;
  width: 100% !important;
  padding: 10px !important;
  margin-bottom: 14px !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  background-color: transparent !important;
  border: 1px solid #919497 !important;
}
form.shop-form-reviews {
  margin-top: 15px !important;
}
.store-description button#submit {
  padding: 10px 30px;
  font-size: 16px;
  background-color: transparent;
  border: 1px solid #919497;
}
section.section-products {
  background: #e3e3de;
}
.section-products .header h4 {
  text-align: center;
  font-size: 30px;
  font-weight: 600;
}
.img-gallery {
  transition: all 0.3s ease;
}
.img-gallery:hover {
  transform: scale(1);
  -webkit-filter: brightness(0.5);
  filter: brightness(0.5);
}
.img-gallery-div {
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
p.errormessage {
  font-size: 12px;
  color: #f90c0c;
  text-align: left;
  margin-top: 3px;
  position: absolute;
  left: 18px;
}
.img-gallery-div:hover {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -webkit-filter: brightness(0.5);
  filter: brightness(0.5);
}
.live-podcast:hover img {
  -webkit-filter: brightness(0.5);
  filter: brightness(0.5);
}

.head-images-news {
  position: absolute;
  top: 0px;
  position: absolute;
  top: 39%;
  left: 4%;
}
.head-images-news h2 {
  color: #fff;
  font-size: 20px;
}

.react-player-setc {
  padding: 6px 6px;
}
/*************** LatesRecaoTvshowvideo **************/
.LatesReacp-view h5 {
  font-size: 22px;
  color: #ffc107;
  font-weight: 500;
  text-align: center;
  margin-bottom: 25px;
}
span.new-share {
  margin-right: 25px;
}
.reacp-tv-text {
  width: 100%;
  color: #ffffffb5;
  padding: 15px 0px;
}
/* .reacp-contnent.new-1 p {
  font-size: 13px;
} */
.slider-section {
  padding: 10px 20px;
}
.reacp-tv-text h3 {
  font-size: 25px;
  /* padding: 0px 5px 5px; */
  font-weight: 400;
  color: #fff;
}
.reacp-tv-text p {
  color: #fff;
  font-size: 14px;
  padding-top: 10px;
}
.share-text i {
  font-size: 16px;
  color: #fff;
  margin-right: 7px;
}
.share-text span {
  font-size: 14px;
  color: #fff;
}
.share-text {
  width: 263px;
  cursor: pointer;
  padding: 8px 10px;
  background: #541117;
}

nav.mobile.navbar.navbar-expand-lg.navbar-light {
  display: none;
}
nav.desktop.navbar.navbar-expand-lg.navbar-light {
  display: block;
}

@media (max-width: 767px) {
  .top-header-top {
    display: none;
  }
  .reacp-listen {
    height: 55px;
}
  .add-desktop-div {
    position: absolute;
    z-index: 9999;
    width: 100%;
    background: #ffc107;
  }

  .section-products .header h4 {
    font-size: 25px;
  }
  .round-black-btn {
    min-width: 250px;
    font-size: 15px;
  }
  .product-count .qtyminus,
  .product-count .qtyplus {
    width: 34px;
    height: 34px;
    font-size: 16px;
  }
  .qnt {
    font-size: 15px;
    width: 50px;
  }
  span.product-detalis-page-q {
    font-size: 15px !important;
    margin-left: 12px !important;
  }
}
@media (max-width: 1440px) {
  .video-soundchat img {
    width: 100%;
    height: 550px;
  }
}

.read-replycomment p {
  font-size: 16px;
}
@media only screen and (max-width: 480px) and (min-width: 320px) {
  p.title-download-small {
    font-size: 11px;
  }
  .reacp-listen {
    height: 40px;
}
  /* .card-cat-checkout-man {
    padding: 7px 0px !important;
    width: 260px !important;
  } */
  .news-heading h3 {
    font-size: 17px;
  }
  h5.cart-empty {
    font-size: 15px !important;
    padding: 10px 15px !important;
  }
  .dropdown-item:not(first-child) {
    padding: 5px 0px !important;
  }
  .dropdown-item:not(last-child) {
    padding: 5px 0px !important;
  }

  h6.recap-listenn-pra {
    font-size: 11px;
  }

  p.listen-second {
    font-size: 11px;
  }

  .news-heading.bg-div-color {
    padding: 8px 10px;
    margin: 10px 0px;
  }
  .interview-div {
    margin-top: 40px;
    padding: 0px 12px !important;
  }
  .video-slider.video-slider-mobile-viwe img {
    height: 270px !important;
    object-fit: contain;
  }

  .donate {
    padding: 10px 0px !important;
  }
  .donation-butt-div img {
    width: 85px !important;
    padding: 4px 0px !important;
  }
  .reacp-contnent {
    height: 31px;
  }
  .reacp-contnent .recap-pra {
    font-size: 13px !important;
    padding: 3px !important;
  }
  h5.donation-title {
    font-size: 15px !important;
    text-transform: capitalize;
  }
  .news-heading h5 {
    font-size: 15px !important;
    font-weight: 400 !important;
  }
  .recap-video-heading .recap-video-h1 {
    font-weight: 500 !important;
    font-size: 15px !important;
    letter-spacing: 1px;
  }
  .recap-video-heading .recap-video-pra {
    /* font-size: 13px !important; */
    display: none;
  }

  .reacp-contnent-home {
    font-size: 13px;
  }
  /* .listen-img-div img {
    width: 100px !important;
    height: 100px !important;
  } */

  .h6.recap-listenn-pra {
    font-size: 12px !important;
  }
  p.listen-first {
    font-size: 11px !important;
  }
  .scrolling-comment {
    max-height: 100% !important;
    overflow-y: hidden !important;
    overflow-x: hidden !important;
  }
  .read-comment p {
    font-size: 14px !important;
  }
  .read-replycomment p {
    font-size: 14px !important;
  }

  .recap-video-heading {
    padding: 160px 219px 0px 15px !important;
  }

  .menu-bars {
    font-size: 17px !important;
    width: 30px !important;
    height: 30px !important;
  }
  .header-logo-mobile img {
    width: 40px !important;
    margin-left: 6px !important;
  }
  /* small.pera {
    font-size: 10px !important;
  } */
  .header-menu-2 {
    margin-left: 12px !important;
  }
}

/* @media (max-width: 477px) {
.countinew-shpnig .countinew-empty{
  font-size : 12px !important;
}
} */

@media (max-width: 1024px) {
  .top-header-top {
    width: 70% !important;
  }
  a.heading-mobile-menu-bar.active {
    font-size: 16px;
    font-weight: 700;
    padding: 19px 18px;
    color: #ffc107;
  }

  input.cart {
    width: 43px !important;
    height: 37px !important;
  }

  .card-cat-checkout-man {
    right: 31px !important;
    top: 57px !important;
}

  a.header-menu-2.dota-mobile-div img {
    width: 100px;
  }
  .hesder-good-day {
    display: flex;
    align-items: center;
    height: 55px;
    width: 120px;
    justify-content: space-between;
    position: relative;
}
p.header-menu-2.new--menu1 {
  margin-bottom: 0px;
}
  .header-menu-2 {
    color: #000;
    font-size: 25px;
    display: block !important;
    /* margin-left: 20px; */
  }
  h1.header-coutong {
    right: -5px;
  }
  a.header-menu {
    color: #000 !important;
  }

  a.header-menu-1 {
    display: none;
  }
  a.header-menu {
    border-bottom: 1px solid #e7dcdc;
    padding: 11px 10px;
  }
  nav.mobile.navbar.navbar-expand-lg.navbar-light {
    display: block;
  }
  nav.desktop.navbar.navbar-expand-lg.navbar-light {
    display: none;
  }
}
@media (max-width: 440px) {
  .div-h6 {
    font-size: 12px;
  }
}
@media (max-width: 390px) {
  .logo-text-heading {
    padding-left: 0px;
  }
}
/*****************Login********************/

.register {
  /* display: flex; */
  align-items: center;
  background-image: url(https://soundchatradio.com/wp-content/uploads/2021/04/sc-footer-bgn2.jpg);
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100% !important;
  height: 100vh;
  padding: 30px 50px;
  overflow: auto;
}
.register-inner-box {
  width: 100%;
  display: grid;
  align-items: center;
  height: 100vh;
}
lable.login-lable {
  float: left;
  color: #fff;
  font-size: 14px;
  text-align: left;
  margin-bottom: 6px;
}
.login-demo {
  position: relative;
  margin-bottom: 18px;
}
span.login-user-demo {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  border-radius: 50%;
  background-color: #fff;
  width: 40px;
  height: 40px;
  bottom: 3px;
  left: 3px;
  color: #813a41;
}
.login-text h2 {
  font-size: 27px;
  text-align: left;
  border-bottom: 1px solid;
  padding-bottom: 11px;
  color: #fff;
  padding-top: 0px;
}

.forgot-password-title h2 {
  font-size: 27px;
  text-align: center;
  /* border-bottom: 1px solid;
  padding-bottom: 11px; */
  color: #fff;
  padding-top: 0px;
}
.login-from-btn {
  width: 100%;
  border-radius: 50px;
  border: 2px solid #fff;
  padding: 11px;
  padding-left: 2em;
  outline: none;
  font-size: 15px;
  color: #000;
  font-weight: 600;
  margin-top: 9px !important;
  background: orange;
}
.forgot a {
  font-size: 13px;
  color: #fff;
  float: right;
  font-weight: 300;
  padding-top: 8px;
}
.forgot a:hover {
  color: rgb(228, 207, 20);
}
.rember label {
  font-size: 13px;
  color: #fff;
  margin-bottom: 0px;
  font-weight: 300;
  padding-left: 9px;
}
.rember {
  float: inherit;
}
p.errormessage-1 {
  font-size: 12px;
  color: red;
  /* margin-bottom: 10px; */
  margin-top: 5px;
}
.form__input {
  width: 100%;
  position: relative;
  font-size: 14px;
  padding: 11px 0px 11px 50px;
  border-radius: 50px;
  border: 2px solid #fff;
  outline: none;
  color: #fff;
  background: transparent;
}
span.password-1 {
  position: absolute;
  color: #fff;
  font-size: 16px;
  top: 43px;
  right: 27px;
}
.dont-here p {
  font-size: 14px;
  color: #fff;
  font-weight: 300;
  text-align: center;
}
.dont-here-1 {
  color: #ffc107;
  padding-left: 10px;
  font-weight: 500;
  font-size: 15px;
}

/*************Cart**************/
h5.cart-empty {
  font-size: 17px;
  padding: 20px 15px;
}
.cart-main {
  background: #f9f5f0;
  padding: 50px 0px;
}
.countinew-shpnig i {
  margin-right: 5px;
}
.checkout.block {
  border: 1px solid;
  color: #000;
  text-align: center;
}
table {
  width: 100%;
}
td.text-right.total-new-tt {
  font-size: 14px;
  font-weight: 600;
}

.checkout .block {
  width: 100%;
  border: none;
  margin: 10px 0px 0px 0px;
  text-align: center;
  font-size: 16px;
  color: #fff;
  background: #000;
  padding: 14px;
}
.total-shping-cart h5 {
  font-size: 14px;
  padding-bottom: 16px;
  border-bottom: 1px solid #cdc8c8;
  font-weight: 600;
}
.countinew-empty.county {
  float: right;
}
.countinew-empty.ceconu {
  float: left;
}
.countinew-shpnig .countinew-empty {
  color: #000;
  border: 1px solid #ddd;
  padding: 10px 34px;
  font-size: 14px;
  background-color: orange;
}
.checkout {
  width: 100% !important;
  display: flex;
}
.grand-tut-t1 h6 {
  font-size: 16px;
  font-weight: 600;
}
.grand-tut-t1 span {
  float: right;
  font-size: 16px;
  font-weight: 800;
}
.img-sm {
  width: 80px;
  height: 80px;
}
.itemside .info p {
  font-size: 12px;
}

.itemside .info .title.text-dark {
  margin-bottom: 0px;
  color: #000;
  font-weight: 500;
  font-size: 17px;
  overflow: hidden;
  white-space: nowrap;
  max-width: 160px;
  text-overflow: ellipsis;
}
.itemside .info {
  padding-left: 15px;
}

.table-shopping-cart .price {
  font-size: 15px;
}

th.cart-delete-1 {
  /* cursor: pointer; */
  font-size: 16px !important;
}
.table-shopping-cart .price-wrap {
  margin-top: 25px;
}
input.cart {
  width: 64px;
  height: 36px;
  border: 1px solid #dce3e9;
  margin-top: 20px;
  font-size: 15px;
  font-weight: 900;
  color: orange;
}
.text-muted {
  color: #969696 !important;
}
td.cart-delete {
  font-size: 17px;
}
.card {
  border: none !important;
}
.table > thead > tr > th {
  vertical-align: bottom;
  color: #000;
  font-size: 14px;
  padding: 16px 12px !important;
  border-bottom: none !important;
}
.itemside {
  position: relative;
  display: flex;
  width: 100%;
}
.dlist-align {
  display: flex;
}
[class*="dlist-"] {
  margin-bottom: 5px;
}
.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  padding: 7px 13px;
}
td.cart-delete i {
  position: absolute;
  margin-top: 24px !important;
  color: #cf1313;
  cursor: pointer;
}
.total-shping-cart {
  width: 100%;
  padding: 20px 10px 20px 20px;
}

.price-subtile {
  margin-top: 25px;
  font-size: 15px;
}
/*****************checkout************/
.checkout-bg-default {
  background: #fff;
  padding: 23px;
}
.Checout-main {
  background: #f9f5f0;
  padding: 30px 0px;
}
.checkbox.bg.pull-right span {
  font-size: 14px;
}
.checkbox.bg.pull-right {
  padding-top: 13px;
}
input.form__input-1 {
  width: 100%;
  font-size: 13px;
  border: 1px solid #d5cdcd;
  padding: 13px;
  margin-bottom: 10px;
  margin-top: 9px;
  border-radius: 5px;
}
.checkout-bg-default h4.my-4 {
  font-weight: 600;
  border-bottom: 1px solid #ebdbdb;
  padding-bottom: 12px;
}
lable.billing-checkout {
  font-size: 15px;
}
h6.Accepted-card {
  font-size: 14px;
  font-weight: 300;
  color: #e9172b;
  padding: 12px 0px;
}
.form-group-neety {
  margin-right: 7px;
}
.Checout-main {
  padding: 30px 0px;
}
.checkout-bg-default h5.my-4 {
  font-weight: 600;
  font-size: 15px;
  padding-bottom: 12px;
}
.countinew-empty.ceconu-1 {
  float: right;
}
.main-header-checkout {
  border-bottom: 1px solid #eee;
}
td.main-header-card-tcet {
  font-size: 14px;
  font-weight: 600;
  padding: 13px 0px;
}
.card-cat-checkout-man {
  padding: 10px 0px;
  width: 380px;
  position: absolute;
  right: 36px;
  top: 49px;
  background: #fff;
  box-shadow: -3px 3px 8px #45492324;
}
a.header-menu-1:hover {
  color: white;
}
.dont-here-1:hover {
  color: #fff;
}
.login-text p {
  font-size: 15px;
  color: #fff;
  margin-top: 0px;
  border-bottom: 1px solid;
  padding-bottom: 9px;
}
.login-text h3 {
  font-size: 24px;
  color: #fff;
}
span.password {
  position: absolute;
  font-size: 17px;
  color: #fff;
  top: 36px;
  right: 12px;
}
.form__input_register {
  position: relative;
  color: #fff;
  width: 100%;
  border-radius: 50px;
  border: 2px solid #fff;
  padding: 10px;
  padding-left: 16px;
  outline: none;
  margin: 6px 0px;
  font-size: 14px;
  background: transparent;
}
.media-content-recap-0-section {
  position: relative;
}

@media (max-width: 767px) {
  .news-video-section .react-player__preview {
    width: 100%;
    position: relative;
    height: 500px !important;
  }
  .header-bottam {
    top: 0px !important;
  }
  .header-top-mobile {
    position: sticky !important;
    top: 55px;
    z-index: 1023;
  }
  .view-story-pra p {
    font-size: 15px !important;
  }
  .video-slider img {
    height: 400px !important;
  }
  .home-slider-adv img {
    margin-top: 25px;
  }
}
.media-content img.image {
  position: relative;
  width: 200px;
  object-fit: cover;
  height: 151px;
  border-radius: 10px 10px 0px 0px;
}
.media-content.image {
  display: block;
  width: 100%;
  height: auto;
}
.media-content-text {
  text-align: left;
  padding: 6px;
  font-size: 13px;
  line-height: 17px;
  color: #fff;
}
.media-content-text p {
  font-size: 13px;
  color: #fff;
}

.media-content:hover .overlay-div {
  height: 100%;
}

@media (max-width: 453px) {
  .home-slider-adv img {
    height: 100%;
  }
}

@media (max-width: 991px) {
  .my-profile-ecomerce {
    margin: 10px 0px;
}
  .top-head-section {
    padding: 0px 0px 4px 10px !important;
  }
  .countinew-shpnig .countinew-empty {
    margin-top: 20px;
  }
  .card-total-box {
    margin-top: 35px;
  }
  .content {
    height: 159px !important;
    box-shadow: inset 0 -59px 9px 8px #000000c2 !important;
    padding-top: 112px !important;
  }

  .shop-text h1 {
    padding: 28px 0px 0px 0px;
  }
  /* .product-variation {
    display: block;
  } */
  .main-content {
    height: 100%;
    width: 100%;
    margin: auto !important;
  }

  .cart-main {
    padding: 20px 0px;
  }
  .row.mt-4.nee {
    margin-bottom: 16px;
  }
  /* h2.cart-text.mb-5 {
    margin-bottom: 14px !important;
  } */
}

@media (max-width: 822px) {
  .content {
    height: 159px !important;
    box-shadow: inset 0 -59px 9px 8px #000000c2 !important;
    padding-top: 100px !important;
  }
}
.modal {
  border: none !important;
  --bs-modal-border-color: transparent !important;
}

@media (max-width: 767px) {
  .section-products .single-product img {
    height: 100%;
  }
  h4.product-price {
    font-size: 17px !important;
  }
  .serach-modal {
    padding: 65px 25px !important;
  }
  button.button-cros {
    font-size: 25px !important;
    margin-right: 8px !important;
  }
  .contents h2 {
    font-size: 2.35rem !important;
    padding-bottom: 0px !important;
  }
  .note,
  .search__guide {
    padding: 4px !important;
  }
  #search-input {
    padding: 10px 32px !important;
    width: 75% !important;
  }
  .modal {
    border: none !important;
    --bs-modal-border-color: transparent !important;
  }

  button.navbar-toggler.collapsed {
    border-radius: 50px !important;
    width: 30px !important;
    height: 30px !important;
  }
  .reacp-contnent .recap-pra {
    font-size: 14px;
  }
  .checoutng-1 {
    margin-top: 13px;
  }
  .checkbox.bg.pull-right {
    float: left !important;
    padding-top: 0px;
    padding-bottom: 10px;
  }
  input.form__input-1 {
    margin-bottom: 6px;
    margin-top: 5px;
  }
  lable.billing-checkout {
    font-size: 13px;
  }
  .checkout-bg-default h5.my-4 {
    padding-bottom: 0px;
  }
  td.cart-delete i {
    position: relative;
    top: 24px;
  }
  span.company__logo.register img {
    margin-top: 0px;
  }
  span.company__logo img {
    height: 209px;
    width: 100%;
    object-fit: contain;
  }
  .rember {
    float: left;
  }
  .shop-text h1 {
    font-size: 27px;
    padding: 14px 0px 0px 0px;
  }
  .shop-text h4 {
    padding-top: 0px;
    font-size: 22px;
  }
  .shop-text button {
    margin: 9px 0px 12px 0px;
    font-size: 14px;
    padding: 6px 22px;
  }
  svg.shop-11 {
    margin-right: 0px;
  }
  .store-img img {
    height: 100%;
    /* object-fit: cover; */
  }
  .shop-i img {
    height: 100%;
  }
  .product-dtl {
    padding-top: 10px;
    padding-left: 0px;
  }
  /* .product-variation {
    display: flex;
  } */
  .store-tabs {
    margin-top: 20px !important;
  }
  .store-tabs .nav-tabs .nav-link.active {
    font-size: 13px;
    padding: 10px 5px !important;
  }
  .store-tabs .nav-tabs .nav-link {
    font-size: 13px !important;
    padding: 10px 5px !important;
  }
}

@media (max-width: 767px) {
  .card-cat-checkout-man {
    top: 55px !important;
}
  .listen-img-div {
    display: flex !important;
    align-items: center !important;
    background-color: transparent !important;
    border-radius: 0px !important;
    box-shadow: 0px 0px 0px transparent;
    height: auto !important;
  }
  .listen-img-div img {
    width: 200px;
    height: 200px;
    border-radius: 0px !important;
  }

  .prod-size-color-style {
    color: #000;
    font-weight: 400;
    text-align: center;
    font-size: 12px;
    margin-bottom: 8px;
    margin-left: 9px;
    text-transform: capitalize;
  }
}
@media (max-width: 403px) {
  .content {
    padding-top: 97px !important;
  }
}

@media only screen and (max-width: 575px) and (min-width: 320px) {
  .video-recap-btn-div {
    display: flex;
    align-items: center;
  }
  .pagination > li > a,
  .pagination > li > span {
    margin: 0px 1px;
  }

  .recap-video-heading.recap-d1 {
    width: 100%;
    position: absolute;
    box-shadow: inset 436px 4px 82px -26px #000000c2 !important;
  }
  .recap-icon-w-div {
    left: 19px;
    font-size: 17px !important;
    height: 17px;
    width: 17px;
    padding: 0px 4px;
  }
  .video-recap-btn-div button {
    padding: 6px 26px;
    font-size: 11px;
  }
  .current-trn-section h5 {
    font-size: 20px;
    text-align: center;
    padding: 22px 0px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
}
@media (max-width: 575px) {
  .img-recap img {
    height: 150px !important;
  }
  .product-name{
    font-size: 22px;
  }
  .product-detail-data h3 {
    font-size: 19px;
}
  .recap-icon-w-div {
    left: 32px;
  }
  .h6.recap-listenn-pra {
    font-size: 12px !important;
  }
}
@media (max-width: 412px) {
  p.listen-first {
    font-size: 11px !important;
  }
}
@media (max-width: 425px) {
  .img-recap-home img {
    height: 120px;
    object-fit: cover;
    width: 100% !important;
    border-radius: 15px 15px 15px 15px;
    cursor: pointer;
    border: 2px solid #ffc107a3 !important;
  }

  .news-heading h6 {
    font-size: 14px;
  }
}
@media (max-width: 355px) {
  .video-slider.recap-dd img {
    height: 282px;
  }
}
@media (max-width: 663px) {
  .podcast-bottam > .nav-tabs .nav-item {
    padding: 0px !important;
    font-size: 13px;
  }
}
@media (max-width: 449px) {
  /* .countinew-shpnig .countinew-empty {
    padding: 8px 13px;
    font-size: 12px;
  } */
  td.cart-total-text {
    font-size: 14px;
    padding: 10px 0px 0px 0px;
  }
  .total-shping-cart {
    padding: 14px;
  }

  .grand-tut-t1 {
    margin: 8px 0px 4px 0px;
  }
}
@media (max-width: 425px) {
  .podcast-bottam > .nav-tabs {
    color: #fff;
    border: none !important;
  }

  .store-description h4 {
    font-size: 19px;
  }
  .podcast-bottam > .nav-tabs .nav-item {
    font-size: 1rem !important;
    padding: 10px 2px !important;
  }
  .podcast-bottam .nav-tabs .nav-link.active {
    font-size: 8px !important;
    font-weight: 600;
    border: none !important;
    color: #dc3545 !important;
    padding: 5px 2px !important;
  }

  .product-name {
    font-size: 19px;
    margin-bottom: 0px;
  }
  .product-price-discount span {
    font-size: 16px;
  }
  .section-products .header h4 {
    font-size: 20px;
  }

  .shop-text h4 {
    padding-top: 0px;
    font-size: 18px;
  }
  .product-price-discount {
    padding: 0px 0;
  }
  .title {
    font-size: 15px;
    color: white;
  }
  .links-list li {
    margin: 9px 0px;
  }

  .sc-div img {
    height: 100%;
    width: 100%;
  }
}
@media (max-width: 329px) {
  .store-tabs .nav-tabs .nav-link.active {
    padding: 7px !important;
  }
}
@media (max-width: 575px) {
  .react-player {
    width: 100%;
    height: 290px !important;
  }
}
@media (max-width: 375px) {
  .react-player {
    width: 100%;
    height: 166px !important;
  }
}
@media (max-width: 376px) {
  .h6.recap-listenn-pra {
    font-size: 12px !important;
  }
}

@media (max-width: 767px) {
  .rhap_volume-container {
    display: none !important;
  }

  .showcase {
    height: 330px !important;
  }
  .recap-video-heading {
    padding: 210px 36px 0px 19px;
  }
  .showcase h2 {
    margin-top: 258px !important;
  }
  .recap-video-heading .recap-video-pra {
    font-size: 15px;
  }

  .recap-video-heading .recap-video-h1 {
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  .img-recap img {
    height: 215px;
  }
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .podcast-bo-box > p {
    font-size: 10px !important;
  }
  .podcast-bo-box > h6 {
    font-size: 14px;
  }
  .podcast-bo-box {
    padding: 14px 6px;
  }

  .login-text h1 {
    padding-bottom: 26px;
    padding-top: 17px;
  }
}
@media only screen and (max-width: 491px) and (min-width: 320px) {
  .login-text h1 {
    font-size: 20px;
  }
}
@media (max-width: 575px) {
  .itemside .info {
    padding-left: 6px;
  }
  .itemside .info .title.text-dark {
    font-size: 15px;
  }
}
@media (max-width: 391px) {
  .div-h6 {
    font-size: 11px;
  }
}
@media only screen and (max-width: 1024px) and (min-width: 320px) {
  .social-media-list li a {
    top: -8px;
  }
  .mediy-gallery-div {
    padding: 10px 10px 0px 10px;
  }
  ul.contact-list h6 {
    width: 100%;
    font-size: 17px;
    padding-bottom: 7px;
  }
  .section-header {
    padding: 20px 0px 0px;
    font-size: 22px;
  }
  p.contact-prgrap {
    font-size: 14px;
    margin-bottom: 13px;
  }
  .contact-text {
    font-size: 14px;
  }
  .phone {
    margin-left: 10px;
  }

  .gmail {
    margin-left: 10px;
  }
  .place {
    margin-left: 10px;
  }
  li.list-item {
    margin-bottom: -10px;
  }
  h1.contact-header-form {
    font-size: 17px;
  }
  .section-products .header h2 {
    font-size: 22px;
  }
  .section-products .header {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .podcast-vo-div {
    margin: 6px 0px 0px 0px;
  }
  .podcast {
    padding: 10px;
  }
  .heading-h4-div h4 {
    margin-top: 0px;
    margin-bottom: 10px;
    font-size: 15px;
  }
  .heading h4 {
    font-size: 16px;
    padding: 10px 0px;
  }
  .podcast-heading h4 {
    font-size: 16px;
    padding: 8px 0px;
  }
}

.showcase {
  width: 100%;
  height: 217px;
  position: relative;
  color: white;
  text-align: center;
  border: 2px solid #ffc107a3;
  border-radius: 25px 25px 0px 0px !important;
}
.showcase img {
  border-radius: 25px 25px 0px 0px !important;
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  object-fit: cover !important;
  top: 0;
  left: 0;
}
.showcase .overlay-div {
  width: 100%;
  height: 226px !important;
  background-color: transparent;
  top: 0;
  left: 0;
  box-shadow: inset 0 -81px 15px 3px #000000c2 !important;
  
}

.overlay-div {
  z-index: 99;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #0000009c;
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: 0.5s ease;
}

.showcase h2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  margin-top: 147px;
  font-size: 14px;
  margin-bottom: 0px;
  line-height: 20px;
  overflow: hidden;
  padding: 4px;
}
.showcase p {
  font-size: 14px;
  font-weight: 600;
  color: #ffc107;
  margin-bottom: 0px !important;
}
.day-div {
  position: absolute;
  top: 0px;
  right: 0px;
  background: rgb(0 0 0 / 72%);
  color: white;
  z-index: 9;
  border-radius: 20px 0px 0px 20px;
}

.showcase .react-player__play-icon {
  position: relative;
  z-index: 999;
  top: -43px;
}
i.news-it-icon {
  color: #fff;
  position: relative;
}
@media (max-width: 991px) {
  .showcase h2 {
    margin-top: 148px;
    font-size: 13px;
  }
  .showcase .overlay-div {
    height: 328px !important;
  }
  /* .middle {
    top: 36%;
    left: 83px;
  } */
}
@media (max-width: 395px) {
  .showcase p {
    margin-top: 0px;
  }
  button.plya-store-link {
    font-size: 10px !important;
  }
}

.lates-news-12.react-player__preview {
  border-radius: 25px 25px 0px 0px;
}
ul {
  margin: 0px;
  padding: 0px;
}
.footer-section {
  position: relative;
  background-image: url("https://soundchatradio.com/wp-content/uploads/2021/04/sc-footer-bgn2.jpg");
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100% !important;
}

.footer-donation {
  margin-top: -4px !important;
}
.single-cta i {
  color: #ffc107;
  font-size: 30px;
  float: left;
  margin-top: 8px;
}
.cta-text {
  padding-left: 15px;
  display: inline-block;
}
.cta-text h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}
.cta-text span {
  color: #757575;
  font-size: 15px;
}
.footer-logo img {
  width: 300px;
}

@media only screen and (min-width: 250px) and (max-width: 575px) {
  .footer-logo img {
    width: 243px !important;
    /* margin: 0px auto !important; */
    display: none !important;
  }
}

.footer-text p {
  margin-bottom: 14px;
  font-size: 14px;
  color: #7e7e7e;
  line-height: 28px;
}
.footer-social-icon span {
  color: #fff;
  font-size: 16px;
  margin-left: 12px;
  text-align: center;
  margin-top: 3px;
}
.footer-widget-so ul li a img {
  width: 35px !important;
  margin-bottom: 12px;
}
.footer-social-icon a {
  color: #fff !important;
  font-size: 16px;
  display: flex;
}
.footer-social-icon i {
  height: 35px;
  margin-bottom: 6px;
  width: 35px;
  margin-right: 20px;
  text-align: center;
  line-height: 31px;
}
.footer-social-icon i:hover {
  color: #ffc107;
}

.footer-widget-heading h3 {
  color: #fff;
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 40px;
  position: relative;
}
.footer-widget-heading h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -15px;
  height: 2px;
  width: 50px;
  background: #ffc107;
}
.footer-widget ul li {
  display: inline-block;
  float: left;
  width: 45%;
  margin-bottom: 12px;
  font-size: 16px;
  color: #fff;
}
.footer-widget ul li a:hover {
  color: #ffc107;
}
.footer-widget ul li a {
  color: #fff;
  font-size: 16px;
  text-transform: capitalize;
}

.copyright-area {
  background: #1c1a1a;
  padding: 15px 0 10px 0px;
}
.copyright-text p {
  margin: 0;
  font-size: 14px;
  color: #fff;
}
.footer-new-head {
  display: flex;
  position: relative;
  top: -9px;
}
.copyright-text p a {
  color: #ffc107;
}
.footer-content {
  padding-top: 55px !important;
}
.footer-social-icon {
  display: grid;
  cursor: pointer;
}

@media (max-width: 991px) {
  .footer-logo img {
    width: 100%;
  }
  .foot-new-t {
    padding-top: 55px;
  }
  .footer-content {
    padding-top: 0px !important;
  }
}
@media (max-width: 688px) {
  .button-div {
    width: 121px;
  }

  .foot-new-t {
    padding-top: 32px;
  }
}
@media (max-width: 575px) {
  .reacp-tv-text h3 {
    font-size: 15px;
    font-weight: 600 !important;
    letter-spacing: 1px;
  }
  /* .sound-text p {
    font-size: 13px;
  } */
  .LatesReacp-view h5 {
    font-size: 17px;
    margin-bottom: 12px;
  }

  .button-div {
    width: 160px !important;
    margin: 40px 0px 0px 9px !important;
  }
  .footer-widget ul li {
    width: 34%;
  }
}
@media (max-width: 425px) {
  .footer-widget-heading h3 {
    font-size: 16px;
    margin-bottom: 28px;
  }
  .footer-widget ul li a {
    color: #fff;
    font-size: 14px;
    text-transform: capitalize;
  }
  .footer-social-icon span {
    color: #fff;
    font-size: 14px;
  }
  .footer-social-icon i {
    height: 30px;
    width: 30px;
    line-height: 28px;
  }
}
@media (max-width: 375px) {
  .footer-widget ul li {
    width: 39%;
  }
  .button-div {
    width: 120px;
    margin: 49px 0px 0px 9px;
  }
  .copyright-text p {
    font-size: 13px;
  }
}

@media (max-width: 320px) {
  .right-section-listen > h6 {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .navbar-collapse {
    padding-right: 3px;
    padding-left: 33px;
  }
  .header-top-gif img {
    width: 100%;
    height: 80px;
    /* object-fit: cover; */
  }
}
.forgot-paasword {
  position: fixed;
  background-image: url(https://soundchatradio.com/wp-content/uploads/2021/04/sc-footer-bgn2.jpg);
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100% !important;
  height: 100%;
  /* height: 598px !important; */
  padding: 50px 0px 50px 0px;
  background-attachment: fixed;
}

p.forgot-text {
  text-align: center;
  font-size: 14px;
  /* padding: 5px 0px 12px 0px !important; */
  color: #fff;
}

.company__logo_forgot img {
  width: 255px;
  margin-bottom: -18px;
}
@media (max-width: 991px) {
  .offset-2 {
    margin-left: 0 !important;
  }
}

/* //// */
.rhap_time {
  display: none !important;
}
.rhap_container {
  background-color: transparent !important;
}
.rhap_additional-controls {
  display: none !important;
}

.rhap_progress-section {
  display: none !important;
}
.rhap_container {
  box-shadow: none !important;
  padding: 0px 0px !important;
}

.rhap_stacked .rhap_controls-section {
  margin-top: 0px !important;
}
h6.header-new-bottom-new {
  font-size: 14px;
  padding: 0px 0px 0px 10px;
  text-align: left;
  margin-bottom: 0px;
  color: #ffc107;
  overflow: hidden;
  white-space: nowrap;
  max-width: 160px;
  text-overflow: ellipsis;
}
.rhap_main-controls-button {
  color: #ffc107 !important;
  border: none;
  background-color: transparent;
  font-size: 35px;
  margin-left: 5px;
  margin-bottom: 1px;
  display: flex;
  align-items: center;
}
.box-container {
  position: relative;
  justify-content: space-between;
  height: 26px;
  --boxSize: 2px;
  --gutter: 11px;
  width: calc((var(--boxSize) + var(--gutter)) * 11);
  display: flex;
  top: -30px;
  left: 46px;
}

.box {
  height: 100%;
  width: var(--boxSize);
  background: linear-gradient(40deg, #364562 20%, #ffc107 100%) no-repeat;
  border-radius: 18px;
}

.box.active {
  animation-duration: 1.2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.box1 {
  animation-name: quiet;
}
.box2 {
  animation-name: normal;
}
.box3 {
  animation-name: quiet;
}
.box4 {
  animation-name: loud;
}
.box5 {
  animation-name: quiet;
}
.box6 {
  animation-name: normal;
}
.box7 {
  animation-name: quiet;
}

@keyframes quiet {
  25% {
    transform: scale(0.6);
  }
  50% {
    transform: scale(0.4);
  }
  75% {
    transform: scale(0.8);
  }
}

@keyframes normal {
  25% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.4);
  }
  75% {
    transform: scale(0.6);
  }
}

@keyframes loud {
  25% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.4);
  }
  75% {
    transform: scale(1.2);
  }
}
@media (max-width: 1240px) {
  .box-container {
    --gutter: 8px;
  }
}
@media (max-width: 557px) {
  .box-container {
    --gutter: 5px;
  }
}
/* @media (max-width: 991px) {
  .fade-in-new img {
    height: 100%;
    object-fit: contain;
    margin-bottom: 10px;
  }
} */

@media (max-width: 1030px) {
  .head-images-news {
    text-align: center;
    position: absolute;
    top: 29%;
  }
}
/* chatting */
/*====> Chat CSS <=====*/
.chatting-new-window {
  position: fixed;
  background-image: url(https://soundchatradio.com/wp-content/uploads/2021/04/sc-footer-bgn2.jpg);
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100% !important;
}
/* //////////////////// */
.Toastify {
  font-size: 15px !important;
}
.top-head-section {
  display: flex;
  padding: 4px 0px 4px 10px;
}
p.todu {
  color: #d9edf7;
  margin-left: 14px;
}

/* //////// */
.top-header {
  position: relative;
  background-color: #484545;
}
.form__input_register::placeholder {
  color: #fff !important;
}
.logo_register {
  display: flex;
  align-items: center;
  height: 100%;
}
.logo_register img {
  width: 75%;
}

.login-text {
  padding-top: 17px;
}
.login-logo img {
  width: 100%;
  margin-top: -10px;
}
a.bac-to-home {
  font-size: 14px;
  float: left;
  color: #fff;
}
.dont-here {
  float: right;
}
p.contact-rdused {
  padding: 0px 0px 0px 35px !important;
  margin-bottom: 0px;
  margin-top: -9px;
}
@media (max-width: 996px) {
  a.header-menu {
    padding: 5px 8px;
  }
}
@media (max-width: 1024px) {
  .dropdown-btn {
    margin-right: 0px !important;
  }
  .list-item i {
    line-height: 47px;
  }
  p.contact-rdused {
    padding: 0px 0px 10px 31px !important;
  }
}
@media (max-width: 480px) {
  h6.title-ad-download {
    font-size: 13px !important;
  }
  .img-recap img {
    height: 128px !important;
  }
  .serach-modal {
    padding: 55px 25px !important;
  }

  #search-input {
    width: 68% !important;
    padding: 8px 32px !important;
  }
  .search-button {
    padding: 6px 22px !important;
  }
  .contents h2 {
    font-size: 2.25rem !important;
    padding-bottom: 8px !important;
  }
  .login {
    /* position: relative; */
    padding: 20px 0px !important;
  }
  .company__info {
    bottom: 0px;
  }
  span.company__logo img {
    width: 150px;
  }
}
@media (max-width: 767px) {
  .social-media-list {
    margin-bottom: 0px;
  }
  .forgot-paasword {
    padding: 16px 0px 10px 0px;
  }
  .register {
    position: relative;
    /* padding: 30px 0px 11px 0px; */
  }
  .logo_register img {
    width: 200px;
    margin: 0 auto 30px;
    display: block;
    top: 0px;
  }
  .login {
    /* position: relative; */
    padding: 51px;
  }
  .login-logo img {
    display: none !important;
  }
  .login-text {
    padding-top: 0px;
    margin-bottom: 15px;
  }
  .login-text h2 {
    font-size: 20px;
    padding-bottom: 8px;
  }
}
@media (max-width: 375px) {
  .contents h2 {
    font-size: 1.85rem !important;
    padding-bottom: 4px !important;
  }
  .search-radio-btn {
    font-size: 16px !important;
  }
  lable.login-lable {
    font-size: 13px;
  }
  .login-text h3 {
    padding-top: 15px;
    font-size: 20px;
    color: #fff;
  }
  .dont-here p {
    font-size: 13px;
  }
  .dont-here-1 {
    font-size: 13px;
  }
  a.bac-to-home {
    font-size: 13px;
  }
  .forgot a {
    padding-top: 10px;
  }
  .login-text p {
    font-size: 13px;
    padding-bottom: 5px;
  }
  .form__input_register {
    padding: 11px;
    font-size: 13px;
  }
  span.password {
    top: 34px;
  }
  .login-text h2 {
    font-size: 18px;
    padding-bottom: 8px;
    padding-top: 10px;
  }
}

@media (max-width: 357px) {
  .dont-here-1 {
    padding-left: 1px;
    font-size: 12px;
  }
  .dont-here p {
    font-size: 12px;
  }
  /* h6.title-ad-download {
    font-size: 12px !important;
} */
}

@media (max-width: 384px) {
  p.cros-but-download {
    font-size: 18px !important;
  }
  h6.title-ad-download {
    font-size: 12px !important;
  }
}
/* //////// */
/* Scrollbar Styling */
.scroll-box {
  max-height: 250px;
  overflow: auto;
  width: 100%;
  padding-right: 20px;
}

.scroll-box::-webkit-scrollbar {
  width: 0.4em;
}
.scroll-box::-webkit-scrollbar,
.scroll-box::-webkit-scrollbar-thumb {
  overflow: visible;
  border-radius: 4px;
}
.scroll-box::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
}
.audio-bar {
  position: fixed;
  top: 50%;
  z-index: 9999;
}

.audio_start {
  cursor: pointer;
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: block;
  text-align: center;
  padding: 4px 0px 0px 0px;
  transition: all 0.3s ease;
  color: #2e2e2a;
  background: #ffc107;
  font-size: 20px;
}
.audio_start1 {
  position: relative;
}

.right-div-section-bottom img {
  width: 112px;
  height: 108px;
}
.header-section-bottom {
  position: absolute;
  width: 341px;
}

@media (max-width: 1024px) {
  .header-section-tow {
    width: 32% !important;
  }
  span.sider-mobile-navber {
    color: #ffc107;
    font-size: 22px;
    margin-left: 25px;
}

  .header-top-wrapper {
    height: 80px;
  }
  .header-section-one img {
    height: 80px;
  }
  .logo-soundchat img {
    height: 80px;
  }
  .rhap_play-pause-button {
    font-size: 34px !important;
    width: 34px !important;
    height: 34px !important;
    margin-bottom: -4px !important;
  }

  .box-container {
    height: 34px !important;
    top: -26px !important;
    left: 56px !important;
    align-items: center !important;
    --gutter: 9px !important;
  }
  .logo-text-heading p {
    font-size: 12px !important;
  }

  p.todu {
    margin-left: 20px !important;
  }
}

@media (max-width: 890px) {
  p.todu {
    margin-left: 10px !important;
  }
  .logo-text-heading p {
    font-size: 9px !important;
  }
  .box-container {
    height: 20px !important;
    top: -24px !important;
    left: 47px !important;
    --gutter: 5px !important;
  }
  h6.header-new-bottom-new {
    font-size: 11px !important;
    overflow: hidden !important;
    white-space: nowrap !important;
    max-width: -webkit-max-content !important;
    max-width: -moz-max-content !important;
    max-width: max-content !important;
    text-overflow: ellipsis !important;
  }
  .header-section-one {
    width: 60%;
  }
  .header-section-tow {
    width: 40% !important;
  }
}

.color-item {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 0 0px 0px 10px;
  position: relative !important;
  z-index: 1;
  transition: transform 0.2s ease;
  flex-shrink: 0;
}
.color-chooser {
  display: flex;
  cursor: pointer;
}
.color-item-selected,
.color-item-deletable {
  border: 3px solid #f0b503;
  transform: scale(1.1);
}
.color-item-selected .color-item-deletab::after {
  content: "hii" !important;
  position: absolute !important;
  top: 50% !important;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  color: #fff;
}

.color-item-selected .color-item-deletable::after {
  content: "✖";
  display: none;
}

.color-item-selected.color-item-deletable:hover::after {
  display: block;
}
.color-item-1 {
  width: 36px;
  height: 36px;
  text-align: center;
  font-size: 14px;
  padding: 5px 2px 2px 2px;
  border-radius: 50%;
  border: 1px solid #000;
  margin: 0 0px 0px 10px;
  position: relative !important;
  z-index: 1;
  transition: transform 0.2s ease;
  flex-shrink: 0;
  background-color: transparent;
  color: #000;
}

.color-item-selected-1,
.color-item-deletable-1 {
  border: 2px solid #2874f0;
  transform: scale(1.1);
  color: #fff;
  background-color: #2874f0;
}
.icon-color-size {
  font-size: 18px;
  color: #fff;
  text-align: center;
  padding: 3px 0px;
  margin: 3px 0px 0px 3px;
  font-weight: 700 !important;
}
.error-selected {
  color: #000;
  font-size: 13px;
  text-align: center;
}

.icon-hide-logout {
  display: none !important;
  visibility: hidden !important;
  margin-right: 0px;
}
.popover {
  position: absolute;
  top: 0px;
  bottom: 0px;
}
.view-story-page {
  overflow-x: hidden;
}
/* .view-div-story {
  width: 100%;
  height: 500px;
} */
/* .view-div-story .react-player .react-player__preview {
  border-radius: 5px !important;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
} */
/* .view-div-story img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  overflow: hidden;
} */
.view-icon-story ul li {
  font-size: 20px;
  color: #fff;
  padding: 0px 20px 0px 0px;
  cursor: pointer;
  font-weight: 200;
}
.view-story-pra p {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 0px;
}
/* .view-div-story .react-player__shadow {
  border: 6px solid #fff;
  background: radial-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0) 60%);
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
} */
.count-div {
  position: absolute;
  top: 92px;
  right: 0px;
  z-index: 9;
  background: rgb(0 0 0 / 50%);
}
.count-div p {
  font-size: 20px;
  color: #fff;
}
.hr-style {
  width: 100%;
  border-top: 1px solid #cbc4c4 !important;
}

.halfDiv-div {
  display: flex;
  margin-top: 12px;
}
.read-comment {
  width: 100%;
  background-color: #fff;
  border-radius: 11px;
  margin-left: 6px;
  padding: 2px 8px;
}
.read-comment p {
  font-size: 15px;
  font-weight: 400;
}
.read-comment h5 {
  font-size: 13px;
  color: #000;
  font-weight: 900;
}
.comment-date {
  float: right;
  font-size: 10px;
  font-weight: 400 !important;
  color: #000000b8;
}

.left-sid-div {
  /* width: 100%; */
  padding: 15px 21px 0px 0px;
  /* height: 500px; */
}
.like-comment {
  margin-left: 5px;
  font-size: 17px;
}

@media (max-width: 359px) {
  /* .view-div-story {
    width: 100% !important;
    height: 170px !important;
    padding: 0px !important;
  } */
  .view-story-pra p {
    color: #fff !important;
    font-size: 12px !important;
    padding: 0px 12px !important;
    margin-bottom: 0px !important;
  }
  /* .view-div-story img {
    object-fit: cover !important;
    width: 100% !important;
    height: 162px !important;
    overflow: hidden !important;
  }
  */
  .form {
    display: flex !important;
    padding: 9px !important;
    border-radius: 8px !important;
  }
  .left-sid-div {
    padding: 0px !important;
  }
  .view-icon-story ul li {
    font-size: 20px !important;
    color: #fff !important;
    /* margin-right: 0px; */
    padding: 0px 7px !important;
  }
  .story-video > .react-player__play-icon {
    border-style: solid !important;
    border-width: 10px 0px 10px 16px !important;
    border-color: transparent transparent transparent white !important;
    margin-left: 7px !important;
  }
  .story-video .react-player .react-player__preview .react-player__shadow {
    background: radial-gradient(
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0) 60%
    ) !important;
    border-radius: 30px !important;
    width: 47px !important;
    height: 47px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
}

@media (max-width: 326px) {
  p.todu {
    color: #d9edf7 !important;
    margin-left: 8px !important;
  }
}

.chatting-new-window-newchat {
  position: relative;
  background-image: url(https://soundchatradio.com/wp-content/uploads/2021/04/sc-footer-bgn2.jpg);
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100% !important;
}
@media (max-width: 960px) {
  .box-container {
    --gutter: 6px !important;
    height: 28px !important;
  }
  .logo-text-heading p {
    font-size: 10px !important;
  }
}
@media (max-width: 991px) {
  .offset-2-newchat {
    margin-left: 0 !important;
  }
  p.todu {
    margin-left: 8px !important;
  }
}
.offset-2-newchat.col-lg-8.col-md-12.col-sm-12 {
  margin-left: 150px;
}

@media (max-width: 1440px) {
  .header-menu-1-div-icon {
    display: none !important;
  }
}
@media (max-width: 1349px) {
  .header-menu-1-div-icon {
    display: none !important;
  }
  svg.login-icon-div {
    display: none !important;
  }
  svg.login-icon-div {
    display: none !important;
  }
}
@media only screen and (min-width: 576px) and (max-width: 991px) {
  svg.login-icon-div {
    display: block !important;
  }
  .header-menu-1-div-icon {
    font-size: 21px !important;
    color: #fff !important;
    margin-right: 13px !important;
    display: block !important;
    margin-top: 8px !important;
  }
}

@media (max-width: 575px) {
  .header-menu-1-div-icon {
    font-size: 21px !important;
    color: #fff !important;
    margin-right: 13px !important;
    display: block !important;
    margin-top: 8px;
  }
  svg.login-icon-div {
    display: block !important;
  }
}

.header-menu-1-div-icon {
  display: none;
}

.reply-comment {
  width: 100%;
  padding: 0px 0px 0px 47px;
  position: relative;
}
.reply-text {
  width: 100%;
  color: #fdf0f0c7;
  cursor: pointer;
  position: relative;
  left: 60px;
  right: 0px;
  font-weight: 300;
  font-size: 14px;
  padding: 10px 0px 0px 0px;
}

.sidebar {
  position: relative;
  height: 100%;
  background: #fff;
}

.scrolling-comment {
  max-height: 322px;
  overflow-y: auto;
  overflow-x: hidden;
}
.scrolling-comment::-webkit-scrollbar {
  width: 5px;
}
.scrolling-comment::-webkit-scrollbar-track {
  background-color: transparent;
}
.scrolling-comment::-webkit-scrollbar-thumb:hover {
  background: #7b7e7a;
}
.postBtn-reply {
  position: absolute;
  right: 14px;
  border: none;
  color: #fff;
  font-size: 24px;
  background-color: transparent;
}
input.postCommentreply {
  position: relative;
  color: #fff;
  border: 1px solid #fff;
  width: 100%;
  font-size: 14px;
  padding: 11px;
  border-radius: 30px;
  background-color: transparent;
}

.listing-reply-comment {
  display: flex;
  margin-top: 10px;
}

.read-replycomment {
  background-color: #fff;
  width: 100%;
  border-radius: 5px;
  margin-left: 7px;
  padding: 4px 6px;
}
span.reply-date {
  font-size: 9px;
  float: right;
}

.reply-count-div {
  margin-left: 5px;
  font-size: 11px;
  color: #2383d6;
  font-weight: 300;
}

.podcast-div {
  background: #fff;
  box-shadow: 1px 2px 7px 2px #c0baba;
  min-height: 163px;
  margin-bottom: 15px;
  border-radius: 5px;
  padding: 12px 7px;
  text-align: center;
  margin-top: 10px;
}

@media (max-width: 767px) {
  .tab-click .owl-nav button {
    width: 24px !important;
    height: 24px !important;
  }
  .tab-click .owl-nav button > span {
    margin-left: 0px !important;
    font-size: 25px !important;
  }
  .story-rigth-side-box {
    padding: 20px !important;
  }
}

.tab-click .owl-nav button {
  width: 35px;
  height: 35px;
  text-align: center;
  display: flex !important;
  align-items: center;
  background: #fff !important;
  border-radius: 50px !important;
  color: #9f9e9e !important;
  font-size: 30px !important;
  padding: 0 !important;
  box-shadow: 1px 2px 7px 2px #c0baba;
  justify-content: center;
}
.tab-click .owl-nav {
  display: flex;
  position: absolute;
  top: -39px;
  right: 0;
}
/*************view story pages css ********************/
.story-page-container {
  width: 100%;
  overflow-x: hidden;
}
.story-view {
  width: 100%;
  height: 560px;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
}
.story-p-right {
  padding: 7px 20px 0px 0px;
}

.story-p-left {
  padding: 7px 0px 0px 20px;
}
.img-gallery-count {
  float: right;
  position: absolute;
  right: 48px;
  font-size: 44px;
  color: #fff;
  top: 381px;
}
.story-video .react-player .react-player__preview {
  border-radius: 5px !important;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.story-video .react-player .react-player__preview .react-player__shadow {
  border: 6px solid #fff;
  background: radial-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0) 60%);
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
@media only screen and (max-width: 767px) and (min-width: 320px) {
  .story-view {
    width: 100%;
    height: 300px;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
  }
  .story-p-left {
    padding: 7px 18px 0px 18px !important;
  }
  .story-p-right {
    padding: 7px 18px 0px 18px !important;
  }
}

@media (max-width: 375px) {
  .story-view {
    height: 180px;
  }
}

@media only screen and (max-width: 767px) and (min-width: 575px) {
  .story-video .react-player .react-player__preview .react-player__shadow {
    margin-bottom: 203px !important;
    border: 6px solid #fff;
    background: radial-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0) 60%);
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
}
.navbar-nav {
  margin: 0;
}
nav.mobile.navbar.navbar-expand-lg.navbar-light {
  position: relative;
}
nav.mobile .navbar-nav {
  position: absolute;
  left: 0;
}

.header-top-mobile {
  display: none;
}
@media (max-width: 767px) {
  .header-top {
    display: none !important;
  }
  .header-top-mobile {
    display: block !important;
  }
}
@media (max-width: 1024px) {
  /* .adv-div img {
    height: 400px !important;
} */
  .header-logo-mobile {
    display: block !important;
  }
  .header-top-mobile {
    display: none;
  }
  .mobile-viewlogout {
    display: none !important;
  }
  /* .header-top.desk-head {
  display: none !important;
} */
  h6.header-new-bottom-new-mobile-view {
    font-size: 18px;
    color: #fff;
    margin-left: 10px;
  }
  .header-logo-mobile img {
    /* height: 34px; */
    width: 45px !important;
    margin-left: 10px;
  }
  .button-mobile {
    padding: 6px 9px;
    border: none;
    background: #ffc107;
    color: #000;
    border-radius: 5px;
    /* border: 1px solid #fff; */
    font-size: 12px;
    margin: 0 2px;
  }
  .mobile-view-button-audio-video > .nav-tabs .nav-link {
    background-color: #ffc107 !important;
    color: #000 !important;
    font-size: 17px !important;
    border-radius: 0px !important;
    margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
    background: 0 0;
    border: var(--bs-nav-tabs-border-width) solid transparent;
  }

  .mobile-view-button-audio-video > .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    font-size: 17px !important;
    border-radius: 0px !important;
    color: #fff !important;
    background-color: #000 !important;
    border: 2px solid #ffc107 !important;
  }

  .mobile-view-button-audio-video > .nav-tabs {
    border: none !important;
  }
  .copyright-text p {
    margin: 0;
    font-size: 14px;
    color: #fff;
    text-align: center;
  }
  .footer-mobile-view-none {
    display: none;
  }

  .logo-text-heading-mobile-audio {
    padding: 12px;
    position: absolute;
    bottom: 0px;
    background: #00000087;
    width: 100%;
    border-radius: 10px;
    height: 110px;
  }
  .scroll-to-top {
    background-color: white;
    right: 40px;
    bottom: 40px;
    position: fixed;
    z-index: 2;
    cursor: pointer;
    border-radius: 7px;
    width: 40px;
    height: 40px;
    box-shadow: 0 9px 25px 0 rgba(132, 128, 177, 0.28);
    border: none;
    display: none;
    visibility: hidden;
  }
  .logo-soundchat-mobile-audio img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 10px;
    position: relative;
  }
  .logo-soundchat-mobile-audio {
    height: 410px;
  }

  .footer-logo {
    width: 255px !important;
  }
  .footer-logo img {
    align-items: center !important;
    width: 73% !important;
    display: block !important;
    margin-top: 0px !important;
  }
  .top-head-section-mobile p {
    margin-left: 10px !important;
    color: #fff !important;
    font-size: 11px !important;
    margin-bottom: 0px !important;
  }
  span.todu-mobile {
    margin-left: 10px !important;
  }
  ul.icon-social-mobile-flex li:first-child {
    margin-left: 0;
  }

  ul.icon-social-mobile-flex li {
    margin: 6px;
  }
}

.Soundchat-div .nav.nav-tabs {
  display: none !important;
}
@media (max-width: 425px) {
  .box-container.audio-wave-container.flex {
    /* height: 10px; */
    top: -16px !important;
}
  .logo-soundchat-mobile-audio {
    height: 190px;
  }
  h6.header-new-bottom-new-mobile-view {
    font-size: 15px;
  }
  .logo-text-heading-mobile-audio {
    padding: 0px 0px 0px 10px;
    height: 95px;
  }
}
@media (max-width: 767px) {
  .Soundchat-div .nav.nav-tabs {
    display: flex !important;
    border: none !important;
    justify-content: center;
  }
  .footer-widget-so ul li a img {
    /* display: inline-block; */
    /* float: left; */
    width: 29px;
    margin-bottom: 0px;
    /* font-size: 16px;
    color: #fff; */
  }

  .footer-widget-so {
    margin-top: 25px;
  }
  ul.icon-social-mobile-flex {
    display: flex !important;
  }
  .Soundchat-div .nav.nav-tabs a {
    width: 150px;
    text-align: center;
    margin: 5px 0px;
    padding: 10px;
  }
}
@media (max-width: 1024px) {
  .header-logo-mobile {
    display: none;
  }
  h6.header-new-bottom-new {
    font-size: 13px !important;
  }
}
@media (max-width: 991px) {
  .footer-logo img {
    width: 87% !important;
    margin-top: 50px !important;
  }
}

@media (max-width: 425px) {
  .Soundchat-div .nav.nav-tabs a {
    width: 130px;
    text-align: center;
    margin: 10px 0px 0px 0px;
    padding: 4px;
    font-size: 15px !important;
  }
  .card-cat-checkout-man {
    width: 294px;
    position: absolute;
    right: 20px !important;
    top: 55px !important;
    background: #fff;
  }
  .footer-logo img {
    width: 67% !important;
    margin-top: 0px !important;
  }
  /* .foot-new-t {
  padding-top: 10px !important;
} */
}

.navbar-toggle {
  display: none !important;
}
@media (max-width: 1024px) {
  .navbar-header-menu-mobile {
    position: relative !important;
    display: flex !important;
    align-items: center;
  }
  .header-mobile-box-outer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;
  }
  .menu-bars {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    background: #000;
    color: #fff !important;
    width: 32px;
    height: 32px;
  }

  .nav-menu {
    background-color: #000;
    width: 220px !important;
    height: 100vh !important;
    display: flex !important;
    justify-content: center !important;
    position: fixed !important;
    top: 0 !important;
    left: -100% !important;
    transition: 850ms !important;
  }

  .nav-menu.active {
    left: 0 !important;
    transition: 350ms !important;
  }
  .nav-menu-items {
    width: 100%;
    padding: 0;
  }
  .header-mobile-list .lst-mobile-headerleft-side-bar .header-menu {
    border: none !important;
    color: #ffc107 !important;
    font-size: 16px !important;
    line-height: 44px !important;
    margin-left: 8px !important;
    position: relative;
    bottom: 4px;
}
  .header-menu-2 a:hover {
    color: #000;
  }
  .navbar-toggle {
    background-color: #333;
    width: 100%;
    height: 75px;
    margin: 0px !important;
    border-radius: 0px;
    display: block !important;
  }
  svg.cros-side-bar {
    float: right;
    color: #000;
    font-size: 22px;
    background-color: orange;
  }
  p.mobile-good-massage {
    font-size: 16px;
    font-weight: 600;
    margin: 0px !important;
    color: #ffc107;
  }
  input.postComment {
    /* width: 290px; */
    margin-left: 10px;
    padding: 10px 19px !important;
    border-radius: 6px;
    border: none;
    font-size: 10px !important;
    color: #000;
  }
}

.navbar-header-menu-mobile {
  min-height: 0px;
  display: none;
}
.nav-menu {
  display: none;
}

@media (max-width: 767px) {
  .prod-size-color-style.prod-size-style {
    margin-left: 38px;
    margin-bottom: 8px;
  }
  .section-products .single-product .part-1 ul li {
    font-size: 16px;
  }
  .section-products .single-product .part-1 ul li a {
    margin-bottom: 5px;
    display: inline-block;
    width: 35px;
    height: 35px;
    line-height: 34px;
    background-color: orange;
    color: #000;
    text-align: center;
  }

  .prod-size-color-style.prod-color-style {
    margin-left: 27px;
  }
  .color-item {
    width: 27px;
    height: 27px;
    border-radius: 50%;
    margin: 0 0px 0px 10px;
    position: relative !important;
    z-index: 1;
    transition: transform 0.2s ease;
    flex-shrink: 0;
  }

  .icon-color-size {
    font-size: 17px;
    color: #fff;
    text-align: center;
    padding: 3px 0px;
    margin: 2px 0px 0px 1px;
    font-weight: 700 !important;
  }
  .color-item-selected-1,
  .color-item-deletable-1 {
    border: 2px solid #2874f0 !important;
    transform: scale(1.1) !important;
    color: #fff !important;
    background-color: #2874f0 !important;
  }
  .color-item-1 {
    width: 27px;
    height: 27px;
    text-align: center;
    font-size: 14px;
    padding: 2px 0px 0px 0px;
    border-radius: 50%;
    border: 1px solid #000 !important;
    margin: 0px 0px 4px 10px;
    position: relative !important;
    z-index: 1;
    transition: transform 0.2s ease;
    flex-shrink: 0;
    background-color: transparent;
    color: #000;
  }
}
/* @media (max-width: 425px) {
  .mobile-view-porduct {
    display: flex !important;
  }
} */

@media (max-width: 575px) {
  .footer-logo img {
    align-items: center !important;
    width: 73% !important;
    display: none !important;
    margin-top: 0px !important;
  }
  .shop-text h1 {
    font-size: 22px;
    padding: 0px 0px 0px 0px;
  }
}
.download-section-start {
  background-image: linear-gradient(#d9c620, #c7783b);
}
.download-box-container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 93vh;
  align-items: center;
  text-align: center;
}
.inner-download-box {
  width: 500px;
  padding: 25px;
  margin: 25px;
  text-align: center;
  background: #fff;
}

.download-img-box-soundchat img {
  width: 30%;
}
.heading-download-box h4 {
  font-size: 25px;
  font-weight: 600;
}
.heading-download-box p {
  font-size: 14px;
}
.download-img-link img {
  width: 138px;
  margin: 10px 0px;
}

@media (max-width: 597px) {
  .inner-download-box {
    width: 100%;
    margin: 20px;
    padding: 20px;
    text-align: center;
    background: #fff;
  }
}
@media (max-width: 767px) {
  .heading-download-box h4 {
    font-size: 22px;
    font-weight: 600;
  }
  .heading-download-box p {
    font-size: 12px;
  }
}

@media (max-width: 1400px) {
  .video-slider img {
    height: 800px;
  }
}
.donate {
  color: #ffc107;
  background: #000;
  text-align: center;
  padding: 35px 0px;
}
@media (max-width: 580px) {
  .donation-butt-div img {
    width: 100px;
    padding: 10px 0px;
  }
  .donation-butt-div {
    margin-left: 0px !important;
  }
}
@media (max-width: 767px) {
  h5.donation-title {
    font-size: 21px;
    padding: 10px;
  }

  .dropdown-btn {
    margin-right: 0px !important;
  }
}
h5.donation-title {
  font-size: 25px;
  font-weight: 800;
}
.donation-butt-div img {
  width: 120px;
  padding: 15px 0px;
}

.contact-wrapper {
  background-color: #000000db;
  background-image: url("https://www.upperside.immo/wp-content/uploads/2015/10/contact-background.png");
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: cover;
}
.contact-wrapper::before {
  content: "";
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
}
.contact-middle-div {
  background-color: #ffc1075e;
  padding: 30px;
  margin-top: 50px;
  margin-bottom: 50px;
  box-shadow: 0px 0px 11px #42424299;
}
span.copy-right-span {
  color: green;
}

@media (max-width: 580px) {
  .copyright-text {
    display: block;
  }
}

.padenation-box {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
}
li.page-li {
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  background-color: #fff;
  margin: 6px;
}

li.page-button-pre,
li.page-button-next {
  height: 35px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: orange;
  border: 1px solid #ddd;
  margin: 2px;
}
a.prev-a {
  color: orange;
}
a.next-a {
  color: orange;
}
a.page-a {
  color: #000;
}
li.page-li.pagenumberactive {
  color: #000;
  background-color: orange;
}
a.dis-pre-next-a {
  color: orange;
}
li.break-me {
  font-size: 24px;
  color: orange;
}

a:focus {
  outline: none;
  outline-offset: 0px;
}

a.header-menu-2:focus,
a.header-menu-2 :hover {
  color: #000 !important;
}
@media (max-width: 575px) {
  li.break-me {
    font-size: 15px;
    color: orange;
  }
}

.btn:active:focus,
.btn:focus {
  outline: none;
  outline-offset: 0px;
}
.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  color: transparent;
  background-color: transparent;
  border-color: transparent;
  border: none;
}
.btn:focus-visible {
  outline: none;
}
.networkSpeedClass {
  background-color: #93696dcc !important;
  margin: auto;
  border: none;
  border: 2 solid #ffc1075e !important;
  width: 227px !important;
  position: fixed;
  top: 7px;
  left: 0;
  right: 0;
  padding: 10px !important;
  border-radius: 5px;
  display: flex;
  z-index: 9999;
  color: #fff;
  flex-direction: column;
}

._1alIC .networkSpeedClass {
  border: 2 solid #ffc1075e !important;
}
._2Qem8 {
  color: #fff !important;
  /* flex-grow: 1; */
  font-size: 1.2rem !important;
}
._nqCmj {
  color: #fff !important;
  /* flex-grow: 1; */
  font-size: 1.5rem !important;
  font-weight: 600 !important;
  /* padding: 5px 0; */
}
input.postComment {
  /* width: 290px; */
  margin-left: 10px;
  padding: 7px 12px;
  border-radius: 6px;
  border: none;
  font-size: 13px;
  color: #000;
}
.postBtn-div {
  color: #000;
  background-color: orange;
  padding: 5px 36px;
  font-size: 15px;
  border-radius: 5px;
}
.dropdown-btn {
  font-size: 20px;
  background: transparent !important;
  border: none !important;
  cursor: pointer;
  margin-right: 13px;
}
button:focus:not(:focus-visible) {
  outline: none;
}
.dropdown-items {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  width: 195px;
  background-color: #000;
  position: absolute;
  margin-top: 13px;
  display: flex;
  color: #fff;
  flex-direction: column;
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid #eee;
  right: 0;
}
.dropdown-item {
  padding-top: 0px;
  padding-bottom: 0px;
  cursor: pointer;
}
.dropdown-item:not(first-child) {
  padding: 9px 0px;
}
.dropdown-item:not(last-child) {
  padding: 9px 0px;
}

.isVisible {
  visibility: visible;
}
.isHidden {
  visibility: hidden;
}

.dropdown__link a {
  font-size: 14px;
  color: #fff;
}
.dropdown__link a:hover {
  color: #fff;
}
.dropdown__link {
  font-size: 14px;
}
tr.order-listing-row {
  text-align: center;
  font-size: 14px;
}
.order-listing-head-row .order-heading {
  text-align: center;
}
img.view-order-butt {
  cursor: pointer;
}

/* //order details */

.card.order-details {
  width: 885px;
  margin: 0 auto;
  padding: 10px 42px 40px 42px;
  background-color: #fff;
  box-shadow: rgb(17 12 46 / 15%) 0px 48px 100px 0px;
}
.order-details-main-head {
  background-color: #faf0e4;
  padding: 20px 0px 20px 0px;
}
img.detail-logo {
  width: 150px;
}

th.order-table-new {
  font-weight: 400;
}
h3.order-details {
  font-size: 19px;
}
.order-same-detaul {
  text-align: right;
}
.order-same-detaul p.text-muted {
  font-size: 13px;
}
.order-same-detaul p {
  font-size: 12px;
  color: #000;
}
.order-detail-subtotal {
  padding: 9px 21px 0px 25px;
  background: #ffc107;
}
.order-detail-total h5 {
  font-size: 14px;
  font-weight: 600;
}
.order-detail-total p {
  font-size: 13px;
}

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  padding: 15px !important;
  border-bottom: 1px solid#ddd;
}
td.order-tt {
  font-size: 14px;
}

@media (max-width: 767px) {
  .card.order-details {
    width: 100%;
    padding: 10px 10px;
  }
}

@media (max-width: 375px) {
  img.detail-logo {
    width: 133px;
  }
}

@media (max-width: 375px) {
  .home-slider-adv img {
    height: 459px;
  }
}

.myaccount-info-wrapper {
  max-height: 358px;
  overflow-x: hidden;
}
/* mayprofile */

.myaccount-info-wrapper::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

.myaccount-info-wrapper::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #fff;
}
.my-profile-data {
  background-color: #faf0e4;
  position: relative;
  background-position: center;
  width: 100%;
  padding: 0px 0px 20px 0px;
}
.form-address {
  padding: 10px 20px;
}

.my-profile-ecomerce {
  width: 100%;
  position: relative;
  border-radius: 10px;
  background-color: #fff;
  height: 435px;
  padding: 20px;
  box-shadow: rgb(17 12 46 / 15%) 0px 48px 100px 0px;
}
.my-profile-adress {
  width: 100%;
  position: relative;
  margin: 0 auto;
  height: auto;
  margin-top: 0px;
  border-radius: 10px;
  padding: 35px 25px;
  background-color: #fff;
  box-shadow: rgb(17 12 46 / 15%) 0px 48px 100px 0px;
}
label.my-profile-dt {
  font-size: 14px;
  font-weight: 300;
}
.form-control-2 {
  color: #000 !important;
  display: block !important;
  width: 100% !important;
  padding: 11px !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  background-color: transparent !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 5px !important;
  margin-bottom: 5px !important;
}

img.profile-logo {
  object-fit: cover;
  border-radius: 50%;
  height: 126px;
  width: 126px;
  margin: 0 auto;
  display: block;
}

.avatar-upload {
  position: relative;
  max-width: 205px;
  margin: 0px auto;
  bottom: 16px;
}

.avatar-upload .avatar-edit input {
  display: none;
}

.avatar-upload .avatar-edit {
  position: absolute;
  right: 40px;
  font-size: 15px;
  width: 36px;
  height: 36px;
  background: #fff;
  text-align: center;
  border-radius: 50%;
  box-shadow: 1px 1px 5px 2px #96919196;
  z-index: 1;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.avatar-preview img {
  position: relative;
}
.avatar-upload .avatar-edit input + label:hover {
  background: #f1f1f1;
  border-color: #d6d6d6;
}

.avatar-upload .avatar-preview > div {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

@media (max-width: 767px) {
  .my-profile-adress {
    margin-bottom: 30px;
    padding: 47px 0px 35px 0px;
  }
}

button.billing-btn {
  padding: 10px 20px;
  font-size: 14px;
  background: #ffc107;
  border: navajowhite;
  color: #000;
  /* 
  margin-top: 14px; */
  border-radius: 3px;
}

.my-profile-save h5 {
  text-align: left;
  padding: 20px 0px;
  font-size: 22px;
}
.myaccount-info-wrapper .card-body {
  position: relative;
  padding: 22px;
  background-color: #f9f9f9;
  border: 0.1rem solid #ebebeb;
  min-height: 181px;
  display: grid;
  align-items: center;
}
button.New-assress {
  background: transparent;
  border: none;
  width: 100%;
  position: relative;
  top: 0px;
}
div#myaddress {
  margin-top: 20px;
}
h4.editt-b {
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 10px;
  text-transform: capitalize;
}
.card-body p {
  font-weight: 400;
  font-size: 14px;
  line-height: 10px;
  color: #777;
  margin-bottom: 12px;
  text-transform: capitalize;
}
h3.plus-dummy {
  border-radius: 0px;
  font-size: 54px;
  margin: 0 auto;
}

h3.plus-dummy.empty-plus-dummy {
  height: 88px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.at-add {
  border-bottom: 1px solid #f5eded;
  padding-bottom: 10px;
  padding-top: 10px;
  border-top: 1px solid#f5eded;
}
div#contained-modal-title-vcenter {
  width: 100%;
}
.my-profile-adress h4 {
  text-align: center;
  color: #777;
  font-weight: 400;
  font-size: 12px;
}
h4.my-data-profile {
  font-size: 15px;
  color: #000;
}
.my-profile-adress span {
  font-size: 13px;
  color: #777;
}
.inner-cart-shipping {
  display: flex;
  justify-content: end;
  position: absolute;
  top: 14px;
  right: 8px;
}
.profile-delte {
  color: #b95353;
  cursor: pointer;
  text-align: right;
  font-weight: 600;
  font-size: 15px;
  background: transparent;
}
.profile-edit {
  position: relative;
  right: 11px;
  background: transparent;
  text-align: right;
  font-size: 15px;
  color: #d58c0d;
  font-weight: 600;
  border: none;
  top: 0px;
  cursor: pointer;
}
.new-add {
  text-align: right;
}
button.add-btn-profil {
  margin-bottom: 10px;
  background: transparent;
  padding: 4px 20px;
  font-size: 14px;
  border: none;
  border-radius: 2px;
  border: dotted 1px #d9d1d1;
  border: dashed 1px solid #000;
}
h3.add-new-addres {
  padding: 0px 0px 11px 0px;
  margin-top: 0px;
}

.btn-group.btn-group-lg {
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 32px 0px;
}
button.profi-btt {
  background-color: #ffc107;
  padding: 10px 20px;
  border: navajowhite;
  font-size: 14px;
  margin-right: 7px;
}

span.password-change {
  position: relative;
  font-size: 17px;
  color: #777;
  top: -41px;
  right: 10px;
  bottom: 0px;
  float: right;
}
/* / my order / */
.order-list-table {
  background: #faf0e4;
  position: relative;
}
.order-0 {
  background-color: #fff;
  box-shadow: rgb(17 12 46 / 15%) 0px 48px 100px;
  /* width: 100%; */
  background: #fff;
  margin: 10px;
  padding: 20px 20px;
}

.oder-hh {
  border-bottom: 1px solid #e9e9e9;
  padding-bottom: 15px;
  margin-bottom: 5px;
  background: #000;
  margin: -20px -20px 10px;
  padding: 10px 30px;
  color: #fff;
}

table.table .avatar {
  border-radius: 50%;
  vertical-align: middle;
  width: 30px;
  margin-right: -66px;
  height: 30px;
  object-fit: cover;
  margin-top: -3px;
}
button.btn-success.stutas {
  border: none;
  border-radius: 20px;
  padding: 1px 11px 2px 11px;
}
button.btn-danger.stutas {
  border: none;
  border-radius: 20px;
  padding: 1px 11px 2px 11px;
}
button.btn-warning.stutas {
  border: none;
  border-radius: 20px;
  padding: 1px 11px 2px 11px;
}
button.btn-info.stutas {
  border: none;
  border-radius: 20px;
  padding: 1px 11px 2px 11px;
}
button.btn-ready.stutas {
  background: #a4542e;
  border: none;
  color: #fff;
  border-radius: 20px;
  padding: 1px 11px 2px 11px;
}
button.btn-way.stutas {
  background-color: #6c757d;
  color: #fff;
  border: none;
  border-radius: 20px;
  padding: 1px 11px 2px 11px;
}
img.view-order-butt {
  cursor: pointer;
  width: 23px;
}

table.table td a {
  color: #198754 !important;
}

@media (max-width: 1199px) {
  table.table .avatar {
    border-radius: 50%;
    vertical-align: middle;
    width: 30px;
    margin-right: -24px;
    height: 30px;
    object-fit: cover;
    margin-top: -3px;
  }
}
/* my order details */
.order-same-detaul h6 {
  font-size: 14px;
  color: #000;
}
.order-same-detaul h6 span {
  color: #c99b11;
  font-size: 13px;
}
th.order-table-new {
  font-size: 15px !important;
  font-weight: 500;
}
.payment-methode p.mb-1 {
  font-size: 14px;
  padding: 5px 0px;
}
h3.main-text-oder-details {
  font-size: 14px;
  font-weight: 600;
}
.payment-methode span.text-muted {
  font-size: 13px;
  font-weight: 500;
  color: #000 !important;
}
.order-currency h6 {
  font-size: 14px;
}
.order-currency p {
  font-size: 14px;
}
.order-currency1 h6 {
  font-size: 14px;
}
.order-currency1 p {
  font-size: 14px;
}
.order-currency2 h6 {
  font-size: 14px;
}
.order-currency2 p {
  font-size: 14px;
}
.order-currency2 {
  text-align: right;
}

h6.order-bttn {
  font-size: 16px;
}
.order-img-new img {
  width: 100px;
  height: 100px;
}
.order-new-site {
  display: flex;
}

.order-new-site-1 p {
  line-height: 18px;
}
td.order-tt {
  font-size: 14px;
  color: #777;
  width: 20px;
}
.payment-methode.new-1 {
  text-align: right;
}
@media (max-width: 1024px) {
  .card.order-details {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .card.order-details {
    padding: 10px 20px 40px 20px;
  }
}

@media (max-width: 767px) {
  .interview-div {
    padding: 10px 12px;
  }
  .payment-methode.new-1 {
    text-align: left;
    padding-top: 18px;
  }
  .payment-methode.new {
    text-align: right;
  }
  .payment-methode p.mb-1 {
    font-size: 14px;
    padding: 5px 0px;
    line-height: 14px;
  }
}

@media (max-width: 575px) {
  .payment-methode.new {
    text-align: right;
  }
}
@media (max-width: 425px) {
  .payment-methode.new {
    text-align: left;
    padding-top: 22px;
  }
  h3.order-details {
    font-size: 16px;
  }
  
}

/* ////////////////////////// */
@media (max-width: 1114px) {
  a.header-menu {
    padding: 5px 7px;
  }
}
@media (max-width: 1060px) {
  a.header-menu {
    padding: 5px 4px;
  }
}

@media (max-width: 1006px) {
  a.header-menu {
    font-size: 14px;
  }
}

/* .cart-text{
  position: relative;
  top: 15px;
} */

@media (max-width: 360px) {
  .header-logo-mobile img {
    margin-left: 5px;
  }
  .modile-downdload-link-box {
    padding: 4px !important;
  }
 
  h2.cart-text {
    font-size: 14px;
  }
  .dropdown-items {
    margin-top: 8px !important;
  }
  
  h6.title-ad-download {
    font-size: 11px !important;
  }
  .view-story-pra p {
    font-size: 14px !important;
  }
  .header-menu-2 {
    font-size: 22px !important;
  }

  .header-mobile-box-outer {
    padding: 2px 10px;
  }

  a.header-menu-2.dota-mobile-div img {
    width: 83px;
  }
}

@media only screen and (max-width: 2500px) and (min-width: 1025px) {
  .dropdown.dropdown-mobile {
    display: none;
  }
}

/* 
/ my checkout-new-16 / */
.checkout-new-16 h4 {
  font-size: 20px;
  font-weight: 700;
  text-align: left;
  padding-bottom: 15px;
}
label.checkout-shiping-addrs {
  font-size: 15px;
  font-weight: 400;
  padding-left: 11px;
}
.checkout-checkox {
  padding-bottom: 11px;
}
td.cart-total-text {
  font-size: 15px;
  padding: 7px 0px 0px 0px;
}
.grand-tut-t1 {
  width: 100%;
  margin: 8px 0px 2px 0px;
  color: #000;
}
.checoutng-1 {
  padding: 10px 0px;
}
.checkouts-ship-add {
  padding: 20px;
}
.checkouts-ship-add p {
  font-size: 18px;
  color: #000;
}
.checkouts-ship-add span {
  font-size: 14px;
  color: #777;
}

button.pay-b {
  display: flex;
  border: none;
  margin: 0 auto;
  padding: 15px 25px;
  border-radius: 30px;
  background-color: orange;
  font-size: 15px;
  font-weight: 600;
}

.StripeElement {
  padding-bottom: 25px;
  padding-bottom: 10px;
}
.payment-paid {
  background: #fff;
  padding-top: 26px;
  width: 86%;
  margin: 0px auto;
}

.countinew-shpnig-empty {
  position: relative;
  bottom: 35px;
  /* left: 130px; */
}
button.cart-inp {
  border: none;
  background-color: transparent;
  font-size: 16px;
  /* margin-left: 15px; */
}
p.valid-postcode-err {
  font-size: 14px;
  color: red;
  text-align: center;
  position: relative;
  top: 10px;
}
button.block.invoice {
  border: none;
  background: orange;
  padding: 8px 11px;
  font-size: 14px;
  /* margin: 7px 0px; */
}
a.view-order-list {
  float: right;
  background-color: orange;
  padding: 10px 12px;
  color: #000;
  font-size: 13px;
}
a.countinew-empty.ceconu-1.check-not-login {
  padding: 10px 17px;
}
a.add-shipping-adderss-checkout {
  background-color: orange;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  padding: 12px;
  display: flex;
  margin: 0px auto;
  width: 113px;
}
p.shipping-add-message {
  font-size: 15px;
  text-transform: capitalize;
  /* padding-top: 25px; */
  font-weight: 500;
}
a.add-shipping-adderss-checkout:hover {
  color: #000;
}
.empty-shipping-addres-box {
  /* display: block; */
  text-align: center;
  position: relative;
  top: 80px;
  bottom: 0px;
}
.card-body-empty-button {
  background-color: #f9f9f9;
  border: 0.1rem solid #ebebeb;
  width: 118px;
  position: relative;
  margin: 0px auto;
}

.react-photo-gallery--gallery img {
  object-fit: cover;
}
tbody.ordre-list-message tr td.non-order {
  padding: 100px 0 !important;
  text-align: center !important;
}
a.no-order-list {
  background: orange;
  padding: 13px;
  font-size: 15px;
  position: relative;
  top: 15px;
  /* margin-top: 14px; */
}
table.table td a.no-order-list {
  color: #000 !important;
}
.order-not-ab {
  font-size: 15px;
  text-transform: capitalize;
  /* padding-top: 25px; */
  font-weight: 500;
}
.start-color {
  color: red;
  font-weight: 700;
}
button.product-outof-stock {
  border: none;
  font-size: 16px;
  padding: 12px 30px;
  background-color: red;
  color: #fff;
  font-weight: 600;
  text-transform: capitalize;
}
span.product-detalis-page-q {
  font-size: 20px;
  color: #258625;
  font-weight: 700;
  margin-left: 15px;
}
.product-qunti-box {
  display: flex;
  align-items: center;
}

/* //reponsive */

@media (max-width: 1311px) {
  a.header-menu {
    font-size: 13px;
  }
}
@media (max-width: 1223px) {
  a.header-menu {
    font-size: 11px;
  }
}
@media (max-width: 356px) {
  .postBtn-div {
    padding: 5px 20px;
  }
}
@media (max-width: 360px) {
  input.postComment {
    padding: 10px 10px !important;
  }
  .img-recap img {
    height: 100px !important;
  }

  .reply-comment .reply-text {
    left: 130px;
  }
}
@media only screen and (max-width: 1157px) and (min-width: 991px) {
  input.postComment {
    /* width: 290px; */
    /* margin-left: 10px; */
    padding: 10px 8px !important;
    font-size: 9px !important;
  }
  .reply-comment .reply-text {
    left: 149px;
  }
}

.story-rigth-side-box {
  padding: 10px 20px 0px 8px;
}
p.errormessage-form {
  color: red;
}
input.search-input-box {
  border-radius: 35px;
  padding: 8px;
  width: 80%;
  border: none;
  border: 1px solid #38353552;
  font-size: 15px;
}
.search-button {
  background-color: transparent;
  color: #fff;
  font-size: 15px;
  padding: 8px 25px;
  border-radius: 28px;
  border: none;
  border: 2px solid #ffc107;
}
.search-radio-btn.search-radio-tow {
  margin-left: 25px;
}
.search-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0px;
}

.ReactModal__Overlay {
  position: fixed !important;
  inset: 0px !important;
  z-index: 9999 !important;
  background-color: #000000c9 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ReactModal__Content {
  position: relative !important;
  inset: inherit !important;
  background: rgb(255, 255, 255);
  overflow: unset !important;
  border-radius: 0px !important;
  outline: none;
  padding: 0px !important;
  width: 800px !important;
  margin: 0px auto !important;
  height: 400px !important;
  border: 1px solid #000 !important;
}

@media (max-width: 767px) {
  .ReactModal__Content {
    margin: 30px !important;
    height: auto !important;
  }
  button.button-cros-home-page {
    font-size: 25px !important;
  }
}

.home-outer-popup-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
button.popup-home-page-b {
  border: none;
  background-color: orange;
  padding: 11px 20px;
  font-size: 15px;
  font-weight: 500;
}

.search-radio-home {
  display: flex;
  align-items: center;
}
.search-radio-btn {
  font-size: 18px;
  font-weight: 300;
  display: flex;
  align-items: center;
}
input.radio-checked {
  margin-right: 6px;
}
.inter-recap-listing-empty {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  color: #fff;
}

.model-main-box {
  position: absolute;
  width: 100%;
  top: 170px;
  border-radius: 6px;
}
.search-bar-model {
  padding: 15px;
}
.serach-modal {
  background: #000000b3;
  padding: 75px 35px;
  color: #fff;
}
.note,
.search__guide {
  width: auto;
  padding: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
}

button.button-cros {
  float: right;
  color: #fff;
  background: transparent;
  border: none;
  font-size: 35px;
  margin-right: 12px;
}

button.button-cros-home-page {
  border: none;
  background: transparent;
  font-size: 35px;
  font-weight: 500 !important;
  position: absolute;
  right: 12px;
  color: orange;
}
code {
  font-size: 14px;
  font-weight: 600;
  background: #01003916;
  padding: 4px 12px;
}

/* / search bar container / */

.search-container {
  display: flex;
  place-items: center;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0px 0px 0px;
}
.contents h2 {
  font-size: 3rem;
  font-weight: 400;
  padding-bottom: 11px;
  text-align: center;
}
#search-input {
  outline: none;
  padding: 10px 32px;
  border-radius: 100px;
  border: 2px rgb(255, 255, 255) solid;
  width: 80%;
  font-size: 15px;
  color: #000;
}
#search-input:focus-within {
  background: #fff;
  border: 2px #ff73005a solid;
}

input[placeholder="Search..."] {
  font-size: 16px;
}

input[placeholder="Search..."]:focus-within {
  color: rgb(26, 34, 34);
}

/* / search suugestion / */

.modile-downdload-link-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #d2691e5c;
  padding: 10px;
}
button.plya-store-link {
  border: none;
  background-color: #000;
  color: #fff;
  padding: 10px 20px;
  font-size: 15px;
  border-radius: 4px;
}
h6.title-ad-download {
  font-size: 17px;
  font-weight: 700;
  margin-bottom: 3px;
}
p.cros-but-download {
  font-size: 22px;
}

@media (max-width: 525px) {
  button.plya-store-link {
    padding: 8px 15px;
    font-size: 14px;
  }
  h6.title-ad-download {
    font-size: 14px;
  }
}
@media (max-width: 425px) {
  button.plya-store-link {
    padding: 7px 10px !important;
  }
}
@media (max-width: 414px) {
  .modile-downdload-link-box {
    padding: 8px;
  }
}

@media (max-width: 480px) {
  .header-top-gif img {
    width: 100%;
    height: 50px;
    object-fit: fill;
  }
}
@media (max-width: 495px) {
  .countinew-shpnig .countinew-empty {
    padding: 10px 10px;
  }
  select#exampleFormControlSelect1 {
    width: 250px !important;
    padding: 6px 0px !important;
    font-size: 13px !important;
}
}
@media (max-width: 399px) {
  .countinew-shpnig .countinew-empty {
    padding: 10px 6px !important;
    font-size: 11px;
  }
}
.user-comment-img {
  height: 42px;
  width: 42px;
  border-radius: 50%;
  background-color: gray;
}
input.input-comment-ui {
  position: relative;
  width: 100%;
  padding: 12px 10px 7px 14px;
  font-size: 14px;
  border-radius: 30px;
  border: 1px solid #fff;
  background-color: transparent;
  color: #fff;
}
button.comment-post-b {
  position: absolute;
  right: 40px;
  margin-top: 2px;
  border: none;
  font-size: 24px;
  background: transparent;
  color: #fff;
}
.contact-logo {
  display: none;
}

a.header-menu.active {
  color: #fff;
}

.media-pagination.story-papination {
  background-color: #000;
}
a.chaticon-mobile {
  display: none !important;
}
input:focus {
  outline: none;
}

.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  position: relative;
  min-height: 1px;
  padding-right: 8px;
  padding-left: 8px;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, 5%);
}

.modal-dialog {
  margin: 0px auto;
}

.audio-header-rigth {
  color: #fff;
  /* float: right; */
  position: absolute;
  right: 8%;
  top: 0px;
}

.section-header-one {
  width: 100% !important;
}

.section-header-two {
  background-color: #3d3939;
  position: sticky;
  right: 0;
  top: 0px;
  z-index: 999;
  height: 99px;
}

.sliderdiv img {
  width: 100%;
  height: 99px !important;
}
.sliderrow {
  display: flex;
}
@media (max-width: 1152px) {
  .logo-soundchat img {
    height: 99px;
  }
  h6.header-new-bottom-new {
    font-size: 12px;
    /* margin-top: 2px; */
  }
  .rhap_main-controls-button {
    font-size: 32px;
  }
  .top-head-section {
    padding: 4px 0px 8px 10px !important;
  }
}
@media (max-width: 800px) {
  h6.header-new-bottom-new {
    font-size: 11px !important;
  }
  .rhap_play-pause-button {
    font-size: 42px !important;
    width: 33px !important;
    height: 33px !important;
    margin-bottom: -7px !important;
    padding-top: 15px !important;
  }
  .box-container {
    top: -15px !important;
    --gutter: 6px !important;
  }

}
.top-header-top {
  position: sticky;
  width: 74%;
  top: 0px;
  z-index: 1024;
  float: left;
}

.top-header-top-other {
  position: sticky;
  width: 100%;
  top: 0px;
  z-index: 1024;
  /* float: left; */
}
.sliderdiv-for-other img {
  width: 100%;
  height: 99px;
}
@media (max-width: 880px) {
  .top-header-top {
    width: 60% !important;
  }
}

.listen-div .react-slideshow-container {
  min-height: 660px;
}


@media (max-width: 2400px) {
  .upperimagediv {
    height: 660px;
  }
  .upperimagediv img {
    height: 100% !important;
  }
  .fade-in-new img {
    height: 840px;
  }
  .listen-img-div img {
    height: 558px;
  }
  .same-value-listen {
    left: -8px;
    display: grid;
    position: relative;
    align-items: center;
  }

  .same-value-listen .box-container-listen {
    justify-content: space-between;
    height: 26px;
    --boxSize: 2px;
    --gutter: 11px;
    width: calc((var(--boxSize) + var(--gutter)) * 11);
    display: flex;
    top: 19px;
    left: 50px;
    position: absolute;
  }
  .same-value-listen .rhap_container {
    height: 65px;
    width: 100%;
    justify-content: space-between;
    position: relative;
  }
}
@media (max-width: 991px) {
  .upperimagediv {
    height: 332px;
  }
  .listen-div {
    min-height: 496px;
  }
  .listen-div .react-slideshow-container {
    min-height: 510px;
}


.fade-in-new img {
    height: 315px;
}
}

@media (max-width: 800px) {
  .same-value-listen .rhap_play-pause-button {
    padding-top: 0 !important;
    margin-bottom: 0 !important;
  }
}

@media (max-width: 767px) {
  .listen-showw .listen-div .react-slideshow-container {
    min-height: 328px;
  }
  .listen-showw .fade-in-new img {
    height: 309px;
  }
  .listen-showw .listen-div {
    min-height: 330px;
  }
  .listen-showw .upperimagediv {
    height: 324px;
  }
  .listen-showw .upperimagediv img {
    height: 100%;
    border-radius: 14px !important;
    width: 351px;
  }
}

/* .upperimagediv {
  height: 324px;
}
.upperimagediv img {
  height: 100% !important;
  border-radius: 14px !important;
  width: 351px;
} */
/* .same-value-listen .box-container-listen {
  top: 15px;
  left: 50px;
  position: absolute;
} */
@media (max-width: 615px) {
  .listen-showw .listen-div {
    min-height: 191px;
  }
  .listen-showw .fade-in-new img {
    height: 176px;
  }
  .listen-showw .listen-div .react-slideshow-container {
    min-height: 126px;
  }
  .listen-showw .upperimagediv {
    height: 188px;
  }
  .listen-showw .upperimagediv img {
    width: 234px;
  }
}

@media (max-width: 512px) {
  .listen-showw .upperimagediv img {
    width: 165px;
  }
}
@media (max-width: 425px) {
.itemside .info .title.text-dark {
  max-width: 100px;
}
}
@media (max-width: 430px) {
  .listen-showw .upperimagediv img {
    width: 124px;
  }
}
@media (max-width: 380px) {
  .listen-showw  .listen-div {
    min-height: 139px;
  }
  .listen-showw .listen-div .react-slideshow-container {
    min-height: 117px;
  }
  .listen-showw .fade-in-new img {
    height: 127px;
  }
  .listen-showw .upperimagediv {
    height: 144px;
  }
  .listen-showw .right-section-listen {
    padding: 14px;
  }
  .listen-showw .listen-new-item {
    padding-top: 0;
  }
  .listen-showw .right-section-listen > h6 {
    font-size: 14px;
    font-weight: 700;
  }
  .listen-showw .same-value-listen .box-container-listen {
    --gutter: 8px;
    left: 42px;
  }
}
@media (max-width: 360px) {
  .listen-showw .same-value-listen .box-container-listen {
    --gutter: 6px;
    left: 40px;
  }
}
@media (max-width: 320px) {
  .listen-showw .upperimagediv {
    height: 161px;
  }
}


/* .home-podcast-story{
  height: 600px;
  background-color: tomato;
}
.news-arive-1{
  height: 600px;
  background-color: b;
} */
select#exampleFormControlSelect1 {
  border: none;
  background-color: #fff;
  width: 400px;
  padding: 9px 0px;
  margin-left: 20px;
  border-radius: 3px;
  font-size: 17px;
}
.info p.cart-size-color {
  margin-bottom: 0px !important;
  font-size: 12px;
  color: gray;
  padding: 2px 0px;
}





















