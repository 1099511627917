
@import "./asstes/bootstrapcss/bootstrap.min.css";
@import "./asstes/bootstrapcss/bootstrap-theme.min.css";
@font-face {
  font-family: "Regular";   /*Can be any text*/
  src: local("Regular"),
    url("./fonts/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Bold";   /*Can be any text*/
  src: local("Bold"),
    url("./fonts/Poppins-Bold.ttf") format("truetype");
}


@font-face {
  font-family: "Medium";   /*Can be any text*/
  src: local("Medium"),
    url("./fonts/Poppins-Medium.ttf") format("truetype");
}
